<div ngbDropdown placement="bottom-right" (openChange)="handleDropdownClosed($event)">
    <button type="button" class="btn btn-transparent me-1" ngbDropdownToggle>
        <fa-icon [fixedWidth]="true" [icon]="faBell"></fa-icon>
        <ng-container *ngIf="notificationsService.unreadNotificationsStream | async as unreadNotifications">
            <span class="badge bg-primary badge-pill" *ngIf="unreadNotifications > 0">
                {{ unreadNotifications | number: '1.0-0' }}
            </span>
        </ng-container>
    </button>
    <div class="dropdown-menu-w-lg dropdown-menu-right" ngbDropdownMenu>
        <div class="dropdown-header text-center">
            {{ 'common.notifications' | translate }}
            <button
                type="button"
                class="btn btn-link text-muted e2e-mark-all-as-read-btn"
                (click)="markAllAsRead(); $event.stopPropagation()"
                [ngbTooltip]="'common.mark-all-as-read' | translate"
                placement="bottom"
            >
                <fa-icon [icon]="faEye"></fa-icon>
            </button>
        </div>
        <div class="dropdown-divider mb-0"></div>
        <div class="scrollable">
            <a
                href
                class="dropdown-item {{ 'has-' + notification.severity }} e2e-header-notification"
                *ngFor="let notification of notifications"
                [ngClass]="{ active: !notification.read }"
                (click)="goto(notification); $event.preventDefault()"
            >
                <p class="mb-0">
                    <span class="badge me-1" [ngClass]="'bg-' + notification.severity" *ngIf="!notification.read">{{
                        'common.new' | translate
                    }}</span>
                    <notification [notification]="notification" [allowUserCopy]="false"></notification>
                </p>
                <small class="text-muted">{{ notification.createdAt | formatDistanceToNow: true : true }}</small>
            </a>
            <div class="dropdown-item text-center bg-white py-3" *ngIf="notifications?.length === 0">
                <span class="text-muted">{{ 'header.no-notifications-found' | translate }}</span>
            </div>
        </div>
        <div class="dropdown-divider mt-0"></div>
        <a class="dropdown-item text-center" [routerLink]="['/notifications']" ngbDropdownItem>{{ 'common.view-all' | translate }}</a>
    </div>
</div>
