import { Location } from '@angular/common'
import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { SessionService } from './session.service'

declare let gtag: (...args: any[]) => void
declare let dataLayer: any[]

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    private initialized = false
    constructor(
        private router: Router,
        private location: Location,
        private session: SessionService
    ) {}

    /**
     * Initliaze google analytics and register page view and user event tracking
     */
    public init(): void {
        if (this.initialized || !window.GOOGLE_ANALYTICS_CODE) {
            return
        }
        this.initialized = true
        this.loadScript()

        // send page view on every transition
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.location.path()),
                distinctUntilChanged()
            )
            .subscribe(url => {
                // log new page path to google analytics
                gtag('config', window.GOOGLE_ANALYTICS_CODE, { page_path: url })
            })

        // log user events
        this.session.userEvent.subscribe(event => {
            this.push('event', event, {
                event_category: 'User Events',
            })
        })
    }

    /**
     * Push events to google analytics
     * @param payload Event payload
     */
    public push(...args: any[]): void {
        if (!window.GOOGLE_ANALYTICS_CODE) {
            return
        }
        gtag(...args)
    }

    /**
     * Load google analytics script
     */
    private loadScript(): void {
        /* eslint-disable */
        ;(function (): void {
            let script = document.createElement('script')
            script.src = `https://www.googletagmanager.com/gtag/js?id=${window.GOOGLE_ANALYTICS_CODE}`
            script.async = true
            document.body.append(script)
            ;(window as any).dataLayer = (window as any).dataLayer || []
            ;(window as any).gtag = function gtag() {
                dataLayer.push(arguments)
            }
            if (environment.appName === 'aerapass') {
                gtag('set', 'linker', { domains: ['aerapass.io', 'app.aerapass.io'] })
            }
            gtag('js', new Date())
            gtag('config', window.GOOGLE_ANALYTICS_CODE)
        })()
        /* eslint-enable */
    }
}
