import { HttpClient, HttpHeaders } from '@angular/common/http'
import { EventEmitter, Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Transaction as ITransaction } from '../api-interfaces'
import { Transaction } from '../models/accounting/transaction.model'

@Injectable({
    providedIn: 'root',
})
export class TransactionService {
    public changeEvent = new EventEmitter<void>()
    public searchEvent = new Subject<void>()
    public layout = environment.layout
    public bitgoSupportedCoins = ['BTC', 'LTC', 'ADA'].concat(environment.appName === 'neopay' ? ['USDT.trc20'] : [])

    constructor(private http: HttpClient) {}

    public changeStatus(id: string, status: 'cancelled' | 'processing', comment?: string): Observable<ITransaction> {
        const patchValue: unknown[] = [
            {
                op: 'replace',
                path: '/status',
                value: status,
            },
        ]

        if (comment) {
            patchValue.push({
                op: 'replace',
                path: '/comment',
                value: comment,
            })
        }
        return this.http.patch<ITransaction>(`/transactions/${id}`, patchValue, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json-patch+json' }),
        })
    }

    public changeAmount(id: string, amount: string, path: string): Observable<ITransaction> {
        const patchValue: unknown[] = [
            {
                op: 'replace',
                path,
                value: amount,
            },
        ]

        return this.http.patch<ITransaction>(`/transactions/${id}`, patchValue, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json-patch+json' }),
        })
    }

    public revert(id: string): Observable<ITransaction> {
        return this.http.post<ITransaction>(`/transactions/${id}/revert`, {})
    }

    public isBitGoTransaction(transaction: Partial<ITransaction>): boolean {
        return transaction.method === 'crypto' && this.bitgoSupportedCoins.includes(transaction.currency?.code || '')
    }

    public restore(id: string): Observable<Transaction> {
        return this.http.post<Transaction>(`/admin/transactions/${id}/restore`, {})
    }
}
