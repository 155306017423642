import { Component, Input, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { environment } from 'src/environments/environment'
import {
    Activity,
    Alert,
    File,
    ICard,
    ICardOrder,
    IConversion,
    ICurrency,
    IFee,
    IpLocation,
    IUser,
    Note,
    Transaction,
} from '../api-interfaces'

@Component({
    selector: 'activity',
    templateUrl: 'activity.component.html',
})
export class ActivityComponent implements OnInit {
    @Input()
    public activity: Activity

    public actor: IUser | null
    public baseUser: IUser | null
    public counterUser: IUser | null
    public ipAddress: string | null
    public data: { [K: string]: string } | null
    public location: IpLocation | null

    public alert: Alert | undefined
    public card: ICard | undefined
    public cardOrder: ICardOrder | undefined
    public conversion: IConversion | undefined
    public currency: ICurrency | undefined
    public fee: IFee | undefined
    public file: File | undefined
    public note: Note | undefined
    public transaction: Transaction | undefined

    /** Used when referring to a user but activity shouldn't be visible on customer side */
    public relatedUser: IUser | undefined

    public displayName = environment.displayName

    public constructor(public translate: TranslateService) {}

    public ngOnInit(): void {
        this.actor = this.activity.actor
        this.baseUser = this.activity.baseUser
        this.counterUser = this.activity.counterUser
        this.ipAddress = this.activity.ipAddress
        this.data = this.activity.data
        this.location = this.activity.location ? this.activity.location : null

        if (this.data) {
            this.alert = (this.data as any).alert
            this.card = (this.data as any).card
            this.cardOrder = (this.data as any).cardOrder
            this.conversion = (this.data as any).conversion
            this.currency = (this.data as any).currency
            this.fee = (this.data as any).fee
            this.file = (this.data as any).file
            this.note = (this.data as any).note
            this.transaction = (this.data as any).transaction
            this.relatedUser = (this.data as any).user
        }
    }

    public join(strings: string[]): string {
        return strings.join(' ,')
    }
}
