import { Component } from '@angular/core'
import { faFrownOpen } from '@fortawesome/pro-light-svg-icons'
import { faHeadset } from '@fortawesome/pro-solid-svg-icons'
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { IntercomService } from '../services/intercom.service'

@Component({
    selector: 'error-modal',
    templateUrl: 'error-modal.component.html',
})
export class ErrorModalComponent {
    public faHeadset = faHeadset
    public faFrownOpen = faFrownOpen

    constructor(
        public activeModal: NgbActiveModal,
        public intercom: IntercomService
    ) {}
}

export function openErrorModal(ngbModal: NgbModal): NgbModalRef {
    const modal = ngbModal.open(ErrorModalComponent, {
        windowClass: 'modal-warning',
    })
    return modal
}
