import { Component, Input, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment'
import { IUser } from '../api-interfaces'
import { WhitelabelService } from '../services/core/whitelabel.service'

@Component({
    selector: 'user-display',
    templateUrl: 'user.component.html',
})
export class UserComponent implements OnInit {
    public appName = environment.appName
    public displayName = this.whitelabelService.getDisplayName()
    @Input()
    public user: IUser
    @Input()
    public displayAccountNumber = false
    @Input()
    public displayBadges = true
    @Input()
    public maxCharacters = 30
    @Input()
    public userLink: string[]

    constructor(private whitelabelService: WhitelabelService) {}

    public ngOnInit(): void {
        if (this.user && !this.userLink) {
            this.userLink = ['/admin/accounts/users', this.user.id]
        }
    }
}
