import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { finalize, flatMap, share } from 'rxjs/operators'
import { SessionService, TokenScope, User } from './session.service'
import { Authentication } from '../api-interfaces'

export interface TokenOptions {
    scopes?: TokenScope[]
    twoFactorCode?: string
    email?: string
    password?: string
    refreshToken?: string
    otpToken?: string
}

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    private tokenRequest: Observable<Authentication> | null = null

    constructor(
        private session: SessionService,
        private http: HttpClient
    ) {}

    public fetch(options: TokenOptions = {}): Observable<Authentication> {
        if (!this.tokenRequest) {
            const body: { [K: string]: string | string[] } = {}
            const potentialEmail = options.email || (this.session.user && this.session.user.email)
            const potentialRefreshToken =
                options.refreshToken || (this.session.hasValidRefreshToken() && this.session.refreshToken!.id)
            if (potentialEmail) {
                body.email = potentialEmail
            }
            if (potentialRefreshToken) {
                body.refreshToken = potentialRefreshToken
            }
            if (options.password) {
                body.password = options.password
            }
            if (options.scopes) {
                body.scopes = options.scopes
            }
            if (options.otpToken) {
                body.otpToken = options.otpToken
            }
            this.tokenRequest = this.http
                .post<Authentication>('/token', body, {
                    headers: options.twoFactorCode
                        ? new HttpHeaders({ 'X-2FA-Code': options.twoFactorCode })
                        : undefined,
                })
                .pipe(
                    share(),
                    finalize(() => {
                        this.tokenRequest = null
                    })
                )
        }
        return this.tokenRequest
    }

    public refresh(options: TokenOptions = {}): Observable<User> {
        return this.fetch(options).pipe(flatMap(({ refreshToken, token }) => this.session.update(refreshToken, token)))
    }
}
