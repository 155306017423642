import { HttpClient } from '@angular/common/http'
import { ChangeDetectorRef, Component, Input } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { UAParser } from 'ua-parser-js'
import { Activity, ActivityType } from '../api-interfaces'
import { ListComponent } from '../list.component'
import { ConfirmationService } from '../services/confirmation.service'
import { SessionService } from '../services/session.service'

export interface ParsedActivity extends Activity {
    parsedUserAgent?: {
        ua: string
        browser?: {
            name?: string
            major?: string
        }
        os?: {
            name?: string
            version?: string
        }
    }
}

@Component({
    selector: 'activities',
    templateUrl: 'activities.component.html',
})
export class ActivitiesComponent extends ListComponent<ParsedActivity> {
    public activeActivity: ParsedActivity | undefined
    @Input()
    public apiUrl = '/activities'

    @Input()
    public compact = false

    @Input()
    public customTitle: string

    public types: (ActivityType | 'fee-updated')[] = [
        'api-call',
        'authentication-failed',
        'authentication-succeeded',
        'card-created',
        'conversion-created',
        'conversion-accepted',
        'currency-created',
        'currency-updated',
        'fee-updated',
        'transaction-created',
        'transaction-processed',
        'transaction-updated',
        'user-created',
        'user-updated',
    ]

    public apiQuery = ['type']

    constructor(
        http: HttpClient,
        ngbModal: NgbModal,
        changeDetector: ChangeDetectorRef,
        route: ActivatedRoute,
        router: Router,
        session: SessionService,
        confirmation: ConfirmationService,
        private sanitizer: DomSanitizer,
        public translate: TranslateService
    ) {
        super(http, ngbModal, changeDetector, route, router, session, confirmation)
    }

    protected mapItem(item: any): ParsedActivity {
        item.parsedUserAgent = new UAParser(item.userAgent).getResult()
        item.dataHtml = this.sanitizer.bypassSecurityTrustHtml(item.dataHtml)
        return item
    }

    protected async changeState(_: Record<string, any>): Promise<void> {
        // do nothing, should not update state
    }
}
