<form #addressForm="ngForm" [ngClass]="{ 'ng-submitted': submitted }" class="form-validation e2e-address-form" novalidate>
    <div class="form-group">
        <div class="d-flex justify-content-between">
            <label class="form-control-label" [ngClass]="{ 'font-md': isLarge }" for="{{ prefix }}addressLine1">{{
                compact ? translate.instant('address-form.address') : translate.instant('address-form.street-and-number')
            }}</label>
            <a href *ngIf="showToggleButton" (click)="$event.preventDefault(); compact = !compact; transformAddress()">{{
                compact ? 'Switch to Expanded Form' : 'Switch to Simple Form'
            }}</a>
        </div>
        <ng-select
            id="{{ prefix }}addressLine1"
            name="addressLine1"
            [bindLabel]="compact ? 'formatted_address' : 'name'"
            [(ngModel)]="addressLine1"
            (change)="setAddress($event)"
            #addressLine1Input="ngModel"
            [ngClass]="{ 'ng-select-lg': isLarge }"
            [items]="placesStream | async"
            [typeahead]="placesAutocomplete"
            aria-autocomplete="false"
            autocomplete="false"
            [required]="requireAll"
            [addTag]="!compact"
            [disabled]="disabled"
            addTagText="Use"
        >
            <ng-template ng-option-tmp let-item="item">{{ item.description }}</ng-template>
            <ng-template ng-footer-tmp>
                <img src="/img/powered_by_google.png" class="powered-by-google-brand" alt="Powered by Google" draggable="false" />
            </ng-template>
        </ng-select>
        <div *ngIf="addressLine1Input.invalid && (submitted || addressLine1Input.dirty)" class="error-messages">
            <div class="form-control-feedback" *ngIf="addressLine1Input?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
        </div>
    </div>
    <ng-container *ngIf="!compact">
        <div class="form-group">
            <label class="form-control-label" [ngClass]="{ 'font-md': isLarge }" for="{{ prefix }}addressLine2">
                {{ 'address-form.suite' | translate }} <span class="text-muted">({{ 'common.optional' | translate }})</span></label
            >
            <input
                type="text"
                class="form-control"
                id="{{ prefix }}addressLine2"
                name="addressLine2"
                [(ngModel)]="address.addressLine2"
                [ngClass]="{ 'form-control-lg': isLarge }"
                [disabled]="disabled"
                emptyToNull
                #addressLine2="ngModel"
            />
        </div>
        <div class="row">
            <div class="form-group col-sm-12">
                <label class="form-control-label" [ngClass]="{ 'font-md': isLarge }" for="{{ prefix }}city">{{
                    'address-form.city' | translate
                }}</label>
                <input
                    type="text"
                    class="form-control"
                    id="{{ prefix }}city"
                    name="city"
                    [(ngModel)]="address.city"
                    #city="ngModel"
                    [ngClass]="{ 'form-control-lg': isLarge }"
                    autocomplete="address-level2"
                    [required]="requireAll"
                    [disabled]="disabled"
                    emptyToNull
                />
                <div *ngIf="city.invalid && (submitted || city.dirty)" class="error-messages">
                    <div class="form-control-feedback" *ngIf="city?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                </div>
            </div>
            <div class="form-group col-sm-12">
                <label class="form-control-label" [ngClass]="{ 'font-md': isLarge }" for="{{ prefix }}zipCode">{{
                    'address-form.postal-code' | translate
                }}</label>
                <input
                    type="text"
                    class="form-control"
                    id="{{ prefix }}zipCode"
                    name="zipCode"
                    [(ngModel)]="address.zipCode"
                    #zipCode="ngModel"
                    [ngClass]="{ 'form-control-lg': isLarge }"
                    autocomplete="postal-code"
                    [required]="requireAll"
                    [disabled]="disabled"
                    emptyToNull
                />
                <div *ngIf="zipCode.invalid && (submitted || zipCode.dirty)" class="error-messages">
                    <div class="form-control-feedback" *ngIf="zipCode?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12">
                <label class="form-control-label" [ngClass]="{ 'font-md': isLarge }" for="{{ prefix }}state">{{
                    'address-form.state' | translate
                }}</label>
                <input
                    type="text"
                    class="form-control"
                    id="{{ prefix }}state"
                    name="state"
                    [(ngModel)]="address.state"
                    #state="ngModel"
                    [ngClass]="{ 'form-control-lg': isLarge }"
                    autocomplete="address-level1"
                    [required]="requireAll"
                    [disabled]="disabled"
                    emptyToNull
                />
                <div *ngIf="state.invalid && (submitted || state.dirty)" class="error-messages">
                    <div class="form-control-feedback" *ngIf="state?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                </div>
            </div>
            <div class="form-group col-sm-12">
                <label class="form-control-label" [ngClass]="{ 'font-md': isLarge }" for="{{ prefix }}country">{{
                    'address-form.country' | translate
                }}</label>
                <ng-select
                    id="{{ prefix }}country"
                    name="country"
                    [(ngModel)]="address.country"
                    bindLabel="name"
                    bindValue="alpha2"
                    [items]="countriesSortedByName"
                    [clearable]="false"
                    #country="ngModel"
                    [ngClass]="{ 'ng-select-lg': isLarge }"
                    [disabled]="disabled"
                    autocomplete="false"
                    aria-autocomplete="false"
                    [required]="requireAll"
                ></ng-select>
                <div *ngIf="country.invalid && (submitted || country.dirty)" class="error-messages">
                    <div class="form-control-feedback" *ngIf="country?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                </div>
            </div>
        </div>
    </ng-container>
</form>
