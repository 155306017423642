import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'sentenceCase' })
export class SentenceCasePipe implements PipeTransform {
    /**
     * Transforms a string into sentence case.
     * Capitalizes the first letter of the first word in a sentence and after punctuation (., !, ?).
     * Also replaces underscores with spaces.
     *
     * @param value - The input value to transform.
     * @returns The transformed string in sentence case, or the original value if it's not a string.
     */
    public transform(value: unknown): string {
        if (typeof value !== 'string') {
            return value as string
        }

        return value
            .replace(/_/g, ' ')
            .replace(/-/g, ' ')
            .toLowerCase()
            .replace(/(^\s*\w|[\.\!\?]\s*\w)/g, match => match.toUpperCase())
    }
}
