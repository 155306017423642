import { HttpClient, HttpHeaders } from '@angular/common/http'
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { faEdit, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { forkJoin, Observable, of } from 'rxjs'
import { catchError, filter, finalize, flatMap, map } from 'rxjs/operators'
import { File as IFile, IUser } from '../api-interfaces'
import { toStartCase } from '../common.mixin'
import { DropzoneComponent } from '../dropzone/dropzone.component'
import { buildFileUrl } from '../file'
import { ListComponent } from '../list.component'
import { UserFileFormComponent } from './user-file-form/user-file-form.component'
import { ConfirmationResult, ConfirmationService } from '../services/confirmation.service'
import { SessionService } from '../services/session.service'
import { ToastrService } from '../services/toastr.service'

@Component({
    selector: 'profile-documents',
    templateUrl: 'profile-documents.component.html',
})
export class ProfileDocumentsComponent extends ListComponent<IFile> implements OnInit, OnDestroy, OnChanges {
    public defaultLimit = 5
    @Input()
    public user: IUser
    @Input()
    public buttonAlign = 'left'
    @Input()
    public showButton = true
    @Input()
    public canCreate = true
    @Input()
    public canView = true
    @Input()
    public canEdit = true
    @Input()
    public canDelete = true
    @Input()
    public purpose: string
    @Input()
    public requiredMessage: string
    @Output()
    public readonly onUpload = new EventEmitter<void>()

    @ViewChild('dropzone')
    public dropzone: DropzoneComponent

    public isUploading = false

    public files: File[] = []
    public existingFiles: IFile[]

    public toStartCase = toStartCase
    public getFileUrl = buildFileUrl
    public faTimes = faTimes
    public faEdit = faEdit

    @Input()
    public apiUrlPrefix = '/admin'
    protected apiUrl: string

    constructor(
        http: HttpClient,
        ngbModal: NgbModal,
        changeDetector: ChangeDetectorRef,
        route: ActivatedRoute,
        router: Router,
        session: SessionService,
        confirmation: ConfirmationService,
        private toastr: ToastrService,
        public translate: TranslateService
    ) {
        super(http, ngbModal, changeDetector, route, router, session, confirmation)
    }

    public ngOnInit(): void {
        this.apiUrl = `${this.apiUrlPrefix}/users/${this.user.id}/files`
        super.ngOnInit()
    }

    public ngOnChanges(): void {
        this.apiUrl = `${this.apiUrlPrefix}/users/${this.user.id}/files`
        this.updateEvent.next()
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy()
    }

    public uploadFiles(): void {
        this.submit()
        if (!this.isValid()) {
            return
        }
        this.isUploading = true
        this.subscriptions.add(
            this.upload()
                .pipe(
                    finalize(() => {
                        this.isUploading = false
                    })
                )
                .subscribe(results => {
                    if (results.some(result => !result)) {
                        this.toastr.error(
                            this.translate.instant('profile-documents.some-requests-has-failed-please-try-again')
                        )
                        return
                    } else if (results.length > 0) {
                        this.toastr.success(this.translate.instant('common.file-uploaded'))
                    }
                    this.onUpload.emit()
                })
        )
    }

    public upload(options?: { purpose?: any }): Observable<(IFile | boolean)[]> {
        return forkJoin(
            this.files.map((file, index) => {
                const headers = {
                    'X-File-Name': encodeURIComponent(file.name),
                    'Content-Type': file.type,
                    'X-No-Toast': 'true',
                } as any
                if (options && options.purpose) {
                    headers['X-File-Purpose'] = options.purpose
                }
                return this.http
                    .post<IFile>(this.apiUrl, file, {
                        headers: new HttpHeaders(headers),
                    })
                    .pipe(
                        map(result => [result, index]),
                        catchError(() => of(false))
                    )
            })
        ).pipe(
            map(results => {
                // Remove successful uploads from pending
                const successfulUploads: number[] = []
                for (const result of results) {
                    if (!Array.isArray(result)) {
                        continue
                    }
                    successfulUploads.push(result[1] as number)
                }
                this.files = this.files.filter((_, index) => !successfulUploads.includes(index))

                this.updateEvent.next()
                return results.map(result => (Array.isArray(result) ? (result[0] as IFile) : result))
            })
        )
    }

    public deleteFile(file: IFile): void {
        this.subscriptions.add(
            this.confirmation
                .show({
                    type: 'danger',
                    text: `${this.translate.instant(
                        'common.are-you-sure-want-to-delete-file'
                    )}<br>${this.translate.instant('common.you-cant-undo-action')}`,
                    confirmText: this.translate.instant('common.delete'),
                    confirmClass: 'danger',
                })
                .pipe(
                    filter(result => result === ConfirmationResult.CONFIRMED),
                    flatMap(() => this.http.delete<void>(`${this.apiUrl}/${file.id}`))
                )
                .subscribe(() => {
                    this.updateEvent.next()
                    this.toastr.success(`${file.name} ${this.translate.instant('common.removed')}`)
                })
        )
    }

    public showFileModal(file: IFile): void {
        const fileForm = this.ngbModal.open(UserFileFormComponent, {
            backdrop: 'static',
            windowClass: 'modal-primary',
        })
        const fileFormComponent = fileForm.componentInstance as UserFileFormComponent
        fileFormComponent.file = file
        if (file.userFile) {
            ;(fileFormComponent as UserFileFormComponent).user = this.user
        }
        fileFormComponent.onSave.subscribe(() => {
            this.updateEvent.next()
            fileForm.close()
        })
    }

    public submit(): void {
        if (this.dropzone) {
            this.dropzone.submit()
        }
    }

    public isValid(): boolean {
        return this.dropzone.isValid()
    }
}
