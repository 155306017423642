<address [ngClass]="theme === 'input' ? 'address-input' : theme">
    <ng-container *ngIf="user"> {{ user.name }}<br /> </ng-container>
    <ng-container *ngIf="address; else noAddress">
        {{ address.addressLine1 }}<br />
        <ng-container *ngIf="address.addressLine2"> {{ address.addressLine2 }}<br /> </ng-container>
        <!-- US format -->
        <ng-container *ngIf="address.city || address.state || address.zipCode">
            {{ address.city ? address.city + ', ' : '' }} {{ address.state }} {{ address.zipCode }}<br />
        </ng-container>
        <ng-container *ngIf="address.country">
            {{ countryMap[address.country]?.name }}
        </ng-container>
    </ng-container>
    <ng-template #noAddress>{{ theme === 'input' ? '-' : '' }}</ng-template>
</address>
