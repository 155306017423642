import { Component } from '@angular/core'
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons'
import { LanguageService } from 'src/app/common/services/language.service'
import { environment } from 'src/environments/environment'

@Component({
    selector: 'app-footer',
    templateUrl: 'footer.component.html',
})
export class FooterComponent {
    public year: number = new Date().getFullYear()
    public appName = environment.appName
    public displayName = environment.displayName
    public helpCenterUrl = window.HELP_CENTER_URL
    public faAngleUp = faAngleUp

    constructor(public languageService: LanguageService) {}
}
