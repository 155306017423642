<ul class="nav nav-tabs b-b-0 fee-schedule-tabs">
    <li class="nav-item">
        <a
            class="nav-link clickable"
            [ngClass]="{ active: currency?.code === usd?.code }"
            (click)="selectCurrency(usd?.code); $event.preventDefault()"
            >{{ getCurrencyName(usd) }}</a
        >
    </li>
    <li *ngFor="let item of setCurrencies" class="nav-item">
        <a
            class="nav-link clickable"
            [ngClass]="{ active: currency?.code === item.code }"
            (click)="selectCurrency(item.code); $event.preventDefault()"
            >{{ getCurrencyName(item) | truncate: false : 14 }}</a
        >
    </li>
    <li class="nav-item" ngbDropdown container="body" placement="bottom-left" *ngIf="unsetCurrencies.length">
        <a class="nav-link clickable" [ngClass]="{ active: isMoreTabActive() }" ngbDropdownToggle>
            {{ moreButtonOptions.text }}
            <fa-icon *ngIf="moreButtonOptions.hasCaret" [icon]="faCaretDown"></fa-icon>
        </a>
        <div class="scrollable" ngbDropdownMenu>
            <div
                class="clickable"
                ngbDropdownItem
                *ngFor="let item of unsetCurrencies"
                (click)="createTabSelectedCurrency(item); $event.preventDefault()"
            >
                {{ getCurrencyName(item) }}
            </div>
        </div>
    </li>
</ul>
