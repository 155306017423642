import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import BigNumber from 'bignumber.js'
import { finalize } from 'rxjs/operators'
import { Wallet } from '../../common/api-interfaces'
import { SecurityCheckService } from '../../common/security-check/security-check.service'
import { ToastrService } from '../../common/services/toastr.service'

@Component({
    selector: 'wallet-form',
    templateUrl: 'wallet-form.component.html',
})
export class WalletFormComponent {
    @Input()
    public action: 'freeze' | 'unfreeze'

    @Input()
    public wallet: Wallet

    @Output()
    public readonly onSave = new EventEmitter<void>()

    public isSaving = false

    @ViewChild('walletForm')
    public walletForm: NgForm

    public amount: string

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        public activeModal: NgbActiveModal,
        private securityCheckService: SecurityCheckService,
        public translate: TranslateService
    ) {}

    public submit(): void {
        if (this.walletForm.invalid) {
            return
        }

        this.securityCheckService.getScopePermission('banking').subscribe(() => {
            this.isSaving = true
            this.http
                .post<Wallet>(
                    `/admin/wallets/${this.wallet.id}/${this.action}`,
                    this.action === 'freeze'
                        ? {
                              amount: new BigNumber(this.amount).toFixed(8, 1),
                          }
                        : {}
                )
                .pipe(
                    finalize(() => {
                        this.isSaving = false
                    })
                )
                .subscribe(() => {
                    this.onSave.emit()
                    this.toastr.success(
                        `${this.translate.instant('common.' + this.action)} ${this.translate
                            .instant('common.wallet')
                            .toLowerCase()} ${this.translate.instant('common.successful')}`
                    )
                })
        })
    }

    public changeAction(action: 'freeze' | 'unfreeze') {
        this.action = action
    }

    public hasFrozenBalance(wallet: Wallet) {
        return new BigNumber(wallet.frozenBalance).isGreaterThan(0)
    }

    public translateAction(action: 'freeze' | 'unfreeze'): string {
        switch (action) {
            case 'freeze':
                return this.translate.instant('common.freeze')
            case 'unfreeze':
                return this.translate.instant('common.unfreeze')
            default:
                return ''
        }
    }
}
