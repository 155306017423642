import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons'
import BigNumber from 'bignumber.js'
import { currencySymbolMap } from 'currency-symbol-map'
import { forkJoin, merge, Observable, of, Subject } from 'rxjs'
import { debounceTime, finalize, map, switchMap, tap } from 'rxjs/operators'
import {
    FeeType,
    IBeneficiary,
    ICurrency,
    IFee,
    IUser,
    Paginated,
    TransactionMethod,
    TransactionType,
} from '../api-interfaces'
import { Currency } from '../models/accounting/currency.model'
import { FeeService } from '../services/accounting/fee.service'
import { SessionService } from '../services/session.service'

@Component({
    selector: 'fee-preview',
    templateUrl: 'fee-preview.component.html',
})
export class FeePreviewComponent implements OnInit, OnChanges {
    public faCircleNotch = faCircleNotch
    public currencySymbolMap = currencySymbolMap
    @Input()
    /* Whether to show a currency symbol prefix. Eg. $1.00 */
    public prefix = false
    @Input()
    public currency: ICurrency | null
    @Input()
    public type: TransactionType
    @Input()
    public method: TransactionMethod
    @Input()
    public amount: string
    @Input()
    public spreadUp: boolean | undefined = undefined
    @Output()
    public readonly fixedFeeChange = new EventEmitter<string | null>()
    @Output()
    public readonly relativeFeeChange = new EventEmitter<string | null>()
    @Output()
    public readonly beneficiaryChange = new EventEmitter<IBeneficiary | null>()
    @Output()
    public readonly currencyChange = new EventEmitter<Currency | null>()
    public isLoading = true
    private fetchEvent = new Subject<void>()
    private currencies: ICurrency[] = []
    private spreadFee: IFee | null = null
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public feeStream: Observable<IFee | null> = merge(of(undefined), this.fetchEvent).pipe(
        tap(() => {
            this.isLoading = true
        }),
        debounceTime(200),
        switchMap(() =>
            !(this.type && this.method && this.currency)
                ? of(null)
                : forkJoin([
                      this.feeService.fetch({
                          type: this.type as FeeType,
                          method: this.method,
                          user: this.session.user ? ({ id: this.session.user.id } as IUser) : null,
                          currency: { code: this.currency.code } as ICurrency,
                      }),
                      this.spreadUp === undefined
                          ? of(undefined)
                          : this.feeService.fetch({
                                type: 'fx-spread',
                                method: 'internal',
                                user: this.session.user ? ({ id: this.session.user.id } as IUser) : null,
                                currency: { code: this.currency.code } as ICurrency,
                            }),
                  ]).pipe(
                      tap(([fee, spreadFee]) => {
                          this.fixedFeeChange.emit(fee && fee.fixed)
                          this.relativeFeeChange.emit(fee && fee.relative)
                          this.currencyChange.emit(fee && new Currency(fee.currency))
                          if (this.type === 'deposit') {
                              this.beneficiaryChange.emit(fee && fee.beneficiary)
                          }
                          this.spreadFee = spreadFee || null
                      }),
                      map(([fee]) => (fee ? fee : ({ relative: '0.0', currency: this.currency } as IFee))),
                      finalize(() => {
                          this.isLoading = false
                      })
                  )
        )
    )

    constructor(
        private http: HttpClient,
        private session: SessionService,
        private feeService: FeeService
    ) {}

    public ngOnInit(): void {
        this.http.get<Paginated<ICurrency>>('/currencies', { params: { limit: 100 + '' } }).subscribe(response => {
            this.currencies = response.data
            this.fetchEvent.next()
        })
    }

    public ngOnChanges(): void {
        this.fetchEvent.next()
    }

    public calculateAmount(fee: IFee): string {
        if (!this.currency || !this.currencies) {
            return '0.0'
        }
        const found = this.currencies.find(currency => this.currency && currency.code === this.currency.code)
        const precision = found ? found.decimalPlaces : 2
        const relativeFeeAmount = new BigNumber(this.amount || 0).times(fee.relative || 0).toFixed(precision || 8, 1)
        return new BigNumber(this.calculateFixedFee(fee)).plus(relativeFeeAmount).toFixed(precision || 8, 1)
    }

    private convert(amount: string, baseCurrency: ICurrency, counterCurrency: ICurrency): string {
        const base = this.currencies.find(currency => currency.code === baseCurrency.code)
        const counter = this.currencies.find(currency => currency.code === counterCurrency.code)
        if (!counter || !base) {
            return '0'
        }
        return Currency.convert(
            amount,
            base,
            counter,
            this.spreadUp !== undefined && this.spreadFee ? this.spreadFee : undefined,
            this.spreadUp
        )
    }

    private calculateFixedFee(fee: IFee): string {
        return new BigNumber(
            fee.fixed && this.currency ? this.convert(fee.fixed, fee.currency, this.currency) : 0
        ).toFixed(8, 1)
    }
}
