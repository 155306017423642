<p class="text-center text-muted" *ngIf="isFetchingPayer">Fetching information...</p>

<div class="mb-3" *ngIf="payer">
    <table class="table table-hover table-bordered table-layout-fixed bg-white">
        <thead>
            <tr>
                <th colspan="2">Bank Information</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Name</td>
                <td class="whitespace-normal">{{ payer.name }}</td>
            </tr>
            <tr>
                <td>Country ISO code</td>
                <td>{{ payer.country_iso_code }}</td>
            </tr>
            <tr>
                <td>Currency</td>
                <td>{{ payer.currency }}</td>
            </tr>
        </tbody>
    </table>
    <table class="table table-hover table-bordered table-layout-fixed bg-white">
        <tbody>
            <tr *ngIf="info.required_sending_entity_fields[0].length">
                <th class="fw-bold" colspan="2">Sender</th>
            </tr>
            <ng-container *ngFor="let item of info.required_sending_entity_fields[0]">
                <tr>
                    <td>{{ item | sentenceCase }}</td>
                    <td>{{ validations.sender[item] || '' }}</td>
                </tr>
            </ng-container>
            <tr *ngIf="info.required_receiving_entity_fields[0].length">
                <th class="fw-bold" colspan="2">Receiver</th>
            </tr>
            <ng-container *ngFor="let item of info.required_receiving_entity_fields[0]">
                <tr>
                    <td>{{ item | sentenceCase }}</td>
                    <td>
                        <span *ngIf="!!validations.receiver[item]; else manualInput">{{ validations.receiver[item] }}</span>
                        <ng-template #manualInput>
                            <ng-container *ngIf="item !== 'id_type' && item !== 'gender'">
                                <div class="form-group mb-0">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="receiver-{{ item }}"
                                        name="receiver-{{ item }}"
                                        placeholder="Fill in {{ item | sentenceCase }}"
                                        [(ngModel)]="collectedData.receiver[item]"
                                    />
                                </div>
                            </ng-container>
                            <ng-container *ngIf="item === 'id_type'">
                                <div class="form-group mb-0">
                                    <ng-select
                                        [name]="item"
                                        [(ngModel)]="collectedData.receiver[item]"
                                        [items]="idTypes"
                                        [clearable]="false"
                                        [searchable]="false"
                                        placeholder="Fill in {{ item | sentenceCase }}"
                                        required
                                    >
                                    </ng-select>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="item === 'gender'">
                                <div class="form-group mb-0">
                                    <ng-select
                                        [name]="item"
                                        [(ngModel)]="collectedData.receiver[item]"
                                        [items]="genders"
                                        [clearable]="false"
                                        [searchable]="false"
                                        placeholder="Fill in {{ item | sentenceCase }}"
                                        required
                                    >
                                    </ng-select>
                                </div>
                            </ng-container>
                        </ng-template>
                    </td>
                </tr>
            </ng-container>
            <tr *ngIf="info.required_documents[0].length">
                <th class="fw-bold" colspan="2">Documents</th>
            </tr>
            <ng-container *ngFor="let item of info.required_documents[0]">
                <tr>
                    <td>{{ item | sentenceCase }}</td>
                    <td>
                        <div class="form-group mb-0">
                            <ng-select
                                [name]="item"
                                [(ngModel)]="collectedData.documents[item]"
                                [items]="transactionFiles"
                                [clearable]="false"
                                [searchable]="false"
                                placeholder="Select {{ item }}"
                                bindValue="id"
                                bindLabel="name"
                                required
                            >
                            </ng-select>
                        </div>
                    </td>
                </tr>
            </ng-container>
            <tr *ngIf="info.credit_party_identifiers_accepted[0].length">
                <th class="fw-bold" colspan="2">Credit Party Identifier</th>
            </tr>
            <ng-container *ngFor="let item of collectCreditIdentifiers">
                <tr>
                    <td>{{ item | sentenceCase }}</td>
                    <td>
                        <ng-container *ngIf="item !== 'account_type'">
                            <div class="form-group mb-0">
                                <input
                                    type="text"
                                    class="form-control"
                                    id="identifier-{{ item }}"
                                    name="identifier-{{ item }}"
                                    [(ngModel)]="collectedData.identifier[item]"
                                    placeholder="Fill in {{ item | sentenceCase }}"
                                />
                            </div>
                        </ng-container>
                        <ng-container *ngIf="item === 'account_type'">
                            <div class="form-group mb-0">
                                <ng-select
                                    [name]="item"
                                    [(ngModel)]="collectedData.identifier[item]"
                                    [items]="accountTypes"
                                    [clearable]="false"
                                    [searchable]="false"
                                    placeholder="Fill in {{ item | sentenceCase }}"
                                    required
                                >
                                </ng-select>
                            </div>
                        </ng-container>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <th class="fw-bold" colspan="2">Additional Information</th>
            </tr>
            <tr>
                <td>Purpose - {{ transaction.purpose }}</td>
                <td>
                    <div class="form-group mb-0">
                        <ng-select
                            name="purpose"
                            [(ngModel)]="collectedData.purpose"
                            [items]="purposes"
                            [clearable]="false"
                            [searchable]="false"
                            required
                        >
                        </ng-select>
                    </div>
                </td>
            </tr>
            <tr>
                <td>Document reference number</td>
                <td>
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            id="documentNumber"
                            name="documentNumber"
                            [(ngModel)]="collectedData.documentNumber"
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <td>Additional</td>
                <td>
                    <div class="form-group mb-0">
                        <input
                            type="text"
                            class="form-control"
                            id="additionalInformation"
                            name="additionalInformation"
                            [(ngModel)]="collectedData.additionalInformation"
                        />
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-hover table-bordered table-layout-fixed bg-white">
        <thead>
            <tr>
                <th colspan="2">Quotation</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Fee</td>
                <td>{{ quotation.fee.amount | amount }} {{ quotation.fee.currency }}</td>
            </tr>
        </tbody>
    </table>
</div>
<ng-container *ngIf="payer === null">
    <p class="text-muted text-center">Transaction not supported</p>
</ng-container>
