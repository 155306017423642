<div class="modal-header">
    <h5 class="modal-title">{{ 'kyc-limit-modal.title' | translate }}</h5>
    <button type="button" class="btn btn-round close close-modal" (click)="activeModal.dismiss()">&times;</button>
</div>
<div class="modal-body text-center">
    <h3 class="m-0">{{ 'kyc-limit-modal.you-have-reached' | translate }}</h3>
    <img src="/img/icons/lock.svg" class="img-md my-3" />
    <p class="text-body">
        {{ 'kyc-limit-modal.please-verify' | translate }}
        <a href (click)="$event.preventDefault(); goToVerification()">{{ 'common.start-now' | translate }}</a>
    </p>
</div>
