<form
    class="form-validation"
    #transactionForm="ngForm"
    (ngSubmit)="submit()"
    [ngClass]="{ 'ng-submitted': transactionForm.submitted }"
    autocomplete="false"
    novalidate
>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'common.process-transaction' | translate }}</h5>
        <button type="button" class="btn btn-round close close-modal" (click)="activeModal.dismiss()">&times;</button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="transaction.type === 'withdrawal'">
            <div class="form-group">
                <div class="form-group">
                    <label for="provider">{{ 'common.select-provider' | translate }}</label>
                    <ng-select
                        bindLabel="label"
                        bindValue="value"
                        [items]="withdrawalProviders"
                        [placeholder]="'common.select-provider' | translate"
                        name="provider"
                        id="provider"
                        [clearable]="false"
                        [ngModel]="selectedProvider"
                        (ngModelChange)="changeProvider($event)"
                        required
                    >
                    </ng-select>
                </div>
            </div>

            <div class="form-group" *ngIf="selectedProvider === 'none'">
                <deposit-instruction-table
                    [beneficiary]="transaction.beneficiary"
                    [transaction]="transaction"
                    *ngIf="transaction.beneficiary"
                ></deposit-instruction-table>
                <div class="pt-2 mb-1"></div>
                <div class="d-flex align-items-center">
                    <h6 class="text-primary pe-1 text-nowrap">{{ 'common.settlement-details' | translate }}</h6>
                    <hr class="w-100 m-0 pb-2" />
                </div>
            </div>
            <div class="mb-3" *ngIf="selectedProvider === 'thunes'">
                <thunes-payout [transaction]="copy" [(collectedData)]="thunesPayload"></thunes-payout>
            </div>
        </ng-container>
        <ng-container *ngIf="transaction.type === 'deposit'">
            <deposit-instruction-table
                [beneficiary]="transaction.beneficiary"
                [transaction]="transaction"
                *ngIf="transaction.beneficiary"
            ></deposit-instruction-table>
            <div class="pt-2 mb-1"></div>
            <div class="d-flex align-items-center">
                <h6 class="text-primary pe-1 text-nowrap">{{ 'common.settlement-details' | translate }}</h6>
                <hr class="w-100 m-0 pb-2" />
            </div>
            <ng-container *ngIf="isSettleInDifferentCurrency; else settledAmount">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <label for="receivedAmount">{{ 'common.received-amount' | translate }}</label>
                        <input
                            type="number"
                            class="form-control"
                            id="receivedAmount"
                            name="receivedAmount"
                            [placeholder]="'common.enter-amount' | translate"
                            [(ngModel)]="copy.receivedAmount"
                            #receivedAmount="ngModel"
                        />
                        <div *ngIf="receivedAmount.invalid && (transactionForm.submitted || receivedAmount.dirty)" class="error-messages">
                            <div class="form-control-feedback" *ngIf="receivedAmount?.errors?.required">
                                {{ 'common.this-is-required' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-sm-12">
                        <label for="currency">{{ 'common.received-currency' | translate }}</label>
                        <ng-select
                            name="receivedCurrency"
                            [placeholder]="'common.select-currency' | translate"
                            [(ngModel)]="copy.receivedCurrency"
                            [items]="currencies"
                            [clearable]="false"
                            [searchable]="false"
                            #receivedCurrencyInput="ngModel"
                        >
                            <ng-template ng-label-tmp let-item="item">{{ item.code }} - {{ item.name }}</ng-template>
                            <ng-template ng-option-tmp let-item="item">{{ item.code }} - {{ item.name }}</ng-template>
                        </ng-select>
                        <div
                            *ngIf="receivedCurrencyInput.invalid && (transactionForm.submitted || receivedCurrencyInput.dirty)"
                            class="error-messages"
                        >
                            <div class="form-control-feedback" *ngIf="receivedCurrencyInput?.errors?.required">
                                {{ 'common.this-is-required' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="row">
                <div class="form-group col-sm-24">
                    <div class="form-check">
                        <input
                            type="checkbox"
                            id="settleInDifferentCurrency"
                            class="form-check-input"
                            name="settleInDifferentCurrency"
                            [(ngModel)]="isSettleInDifferentCurrency"
                            (change)="onSettleInDifferentCurrency()"
                        />
                        <label for="settleInDifferentCurrency" class="form-check-label">{{
                            'common.settle-in-different-currency' | translate
                        }}</label>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!isSettleInDifferentCurrency; else settledAmount"></ng-container>
            <ng-template #settledAmount>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <label for="amount">{{ (isSettleInDifferentCurrency ? 'common.settle-amount' : 'common.amount') | translate }}</label>
                        <input
                            type="number"
                            class="form-control"
                            id="amount"
                            name="amount"
                            [placeholder]="'common.enter-amount' | translate"
                            [(ngModel)]="copy.amount"
                            #amount="ngModel"
                            required
                        />
                        <div *ngIf="amount.invalid && (transactionForm.submitted || amount.dirty)" class="error-messages">
                            <div class="form-control-feedback" *ngIf="amount?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                        </div>
                        <small *ngIf="copy.receivedCurrency && copy.currency.code !== copy.receivedCurrency?.code" class="text-muted float-end"
                            >{{ copy.receivedAmount || 0 }} {{ copy.receivedCurrency?.code }} = {{ convertAmount() }} {{ copy.currency.code }}</small
                        >
                    </div>
                    <div class="form-group col-sm-12">
                        <label for="currency">{{ (isSettleInDifferentCurrency ? 'common.settle-currency' : 'currency') | translate }}</label>
                        <ng-select
                            name="currency"
                            [placeholder]="'common.select-currency' | translate"
                            [(ngModel)]="copy.currency"
                            [items]="currencies"
                            [clearable]="false"
                            [searchable]="false"
                            (change)="fetchFeeEvent.next()"
                            #currencyInput="ngModel"
                            required
                        >
                            <ng-template ng-label-tmp let-item="item">{{ item.code }} - {{ item.name }}</ng-template>
                            <ng-template ng-option-tmp let-item="item">{{ item.code }} - {{ item.name }}</ng-template>
                        </ng-select>
                        <div *ngIf="currencyInput.invalid && (transactionForm.submitted || currencyInput.dirty)" class="error-messages">
                            <div class="form-control-feedback" *ngIf="currencyInput?.errors?.required">
                                {{ 'common.this-is-required' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
        <div class="form-group" *ngIf="selectedProvider === 'none'">
            <label for="external-reference"
                >{{ 'common.external-reference' | translate }} <small class="text-muted">({{ 'common.optional' | translate }})</small></label
            >
            <input
                type="text"
                class="form-control"
                id="external-reference"
                name="external-reference"
                [(ngModel)]="copy.externalReference"
                emptyToNull
                [disabled]="transactionService.isBitGoTransaction(copy) && copy.type === 'deposit'"
            />
        </div>
        <table *ngIf="transaction.type === 'deposit'" class="table table-bordered mb-0">
            <tbody>
                <tr *ngIf="isSettleInDifferentCurrency && copy.receivedCurrency?.code !== copy.currency.code">
                    <td>{{ 'common.exchange-rate' | translate }}</td>
                    <td class="text-end">
                        {{ '1' | amount: copy.receivedCurrency?.decimalPlaces }} {{ copy.receivedCurrency?.code }} =
                        {{ calculateExchangeRate() | amount: copy.currency.decimalPlaces }} {{ copy.currency.code }}
                        <div class="small text-muted">
                            {{ calculateActualExchangeRate() | amount: copy.currency.decimalPlaces }} {{ copy.currency.code }}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>{{ 'common.fee' | translate }}</td>
                    <td class="text-end">
                        {{ calculateFee(copy) | amount: copy.currency?.decimalPlaces }} {{ copy.currency?.code }}
                        <div class="small text-muted">
                            <ng-container
                                *ngIf="(copy.fixedFee && !isZero(copy.fixedFee)) || (copy.relativeFee && !isZero(copy.relativeFee)); else free"
                            >
                                (<ng-container *ngIf="copy.fixedFee && !isZero(copy.fixedFee)"
                                    >{{ copy.fixedFee | amount: copy.currency?.decimalPlaces }} {{ copy.currency?.code }}</ng-container
                                >
                                <ng-container *ngIf="copy.fixedFee && !isZero(copy.fixedFee) && copy.relativeFee && !isZero(copy.relativeFee)">
                                    +
                                </ng-container>
                                <ng-container *ngIf="copy.relativeFee && !isZero(copy.relativeFee)">{{
                                    copy.relativeFee | percentage: 3
                                }}</ng-container
                                >)
                            </ng-container>
                            <ng-template #free>({{ 'common.free-of-charge' | translate | lowercase }})</ng-template>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold">{{ 'common.to-be-credited' | translate }}</td>
                    <td class="text-end text-primary">
                        {{
                            (isZero(copy.amount) || !copy.amount ? '0' : calculateCreditableAmount(copy)) | amount: copy.currency?.decimalPlaces || 2
                        }}
                        {{ copy.currency?.code }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-w-sm" [ngClass]="{ loading: isSaving }" [disabled]="isSaving">
            {{ 'common.process' | translate }}
        </button>
    </div>
</form>
