<ng-container [ngSwitch]="activity.type">
    <!-- Alert -->
    <ng-container *ngSwitchCase="'alert-created'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: alert?.user }"></ng-container> triggered an alert of type
        <span class="text-uppercase text-green">{{ alert?.trigger?.type || 'custom' }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="'alert-updated'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: alert?.user }"></ng-container>'s alert
        <ng-container *ngIf="alert?.status; else alertAssignees"
            >status has been set to <span class="text-uppercase text-green">{{ alert?.status }}</span></ng-container
        ><ng-template #alertAssignees> assignees have been updated</ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'alert-cdd-updated'">
        CDD Flag for <ng-container *ngTemplateOutlet="userTemplate; context: { user: alert?.user }"></ng-container>'s alert has been
        <span class="text-uppercase text-green">{{ alert?.cdd ? 'Enabled' : 'Disabled' }}</span>
    </ng-container>

    <!-- API Call -->
    <ng-container *ngSwitchCase="'api-call'">
        Third-party request to <span class="text-green">{{ data?.name }}</span> calling action <span class="text-info">{{ data?.action }}</span>
    </ng-container>

    <!-- Authentication -->
    <ng-container *ngSwitchCase="'authentication-succeeded'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container> has successfully logged in
        {{ data?.twoFactor ? 'using 2FA' : '' }} from {{ location ? location.city : '' }}<span *ngIf="location?.city">, </span>
        {{ location ? location.country + ' ' : '' }} <span class="text-info">({{ ipAddress }})</span>
    </ng-container>
    <ng-container *ngSwitchCase="'authentication-failed'">
        <span class="text-info">{{ ipAddress }}</span> has failed to log in using <span class="text-info">{{ data?.email }}</span>
        <span *ngIf="location?.city"> from</span> {{ location ? location.city : '' }}<span *ngIf="location?.city">, </span>
        {{ location ? location.country + ' ' : '' }}
    </ng-container>

    <!-- Card -->
    <ng-container *ngSwitchCase="'card-created'">
        Card <span class="text-info">{{ card?.maskedPan }}</span> of
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container> has been created
    </ng-container>
    <ng-container *ngSwitchCase="'card-updated'">
        Card <span class="text-info">{{ card?.maskedPan }}</span> of
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container> has been updated
    </ng-container>
    <ng-container *ngSwitchCase="'card-deleted'">
        Card <span class="text-info">{{ card?.maskedPan }}</span> of
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container> has been deleted
    </ng-container>

    <!-- Card Order -->
    <ng-container *ngSwitchCase="'card-order-cancelled'">
        Card order
        <a [routerLink]="['/admin/cards/orders']" [queryParams]="{ search: cardOrder?.id }" (click)="$event.stopPropagation()" class="text-info">{{
            cardOrder?.id | truncate: false : 7 : ''
        }}</a>
        has been cancelled
    </ng-container>

    <!-- Note -->
    <ng-container *ngSwitchCase="'note-created'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: actor }"></ng-container> wrote a note on
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: relatedUser }"></ng-container> profile
    </ng-container>
    <ng-container *ngSwitchCase="'note-updated'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: actor }"></ng-container> updated a note on
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: relatedUser }"></ng-container> profile
    </ng-container>
    <ng-container *ngSwitchCase="'note-deleted'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: actor }"></ng-container> deleted a note on
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: relatedUser }"></ng-container> profile
    </ng-container>

    <!-- File -->
    <ng-container *ngSwitchCase="'file-created'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: actor }"></ng-container> uploaded a file
        <ng-container *ngIf="note && note.user">
            to note for
            <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: note.user }"></ng-container>
        </ng-container>
        <ng-container *ngIf="note && note.alert">
            to alert
            <a class="text-green text-uppercase" [routerLink]="['/admin/alerts', '']" (click)="$event.stopPropagation()">
                {{ note.alert?.trigger?.type }}
            </a>
            of
            <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: note.alert?.user }"></ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'file-deleted'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: actor }"></ng-container> deleted a file
        <ng-container *ngIf="note">for a note</ng-container>
    </ng-container>

    <!-- Conversions -->
    <ng-container *ngSwitchCase="'conversion-created'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container> requested a conversion of
        {{ conversion?.baseTransaction?.amount | amount: conversion?.baseTransaction?.currency?.decimalPlaces }}
        {{ conversion?.baseTransaction?.currency?.code }} to {{ conversion?.currency?.code }}
    </ng-container>
    <ng-container *ngSwitchCase="'conversion-updated'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container>'s conversion was updated. Status:
        {{ conversion?.status?.toUpperCase() }}, converting
        {{ conversion?.baseTransaction?.amount | amount: conversion?.baseTransaction?.currency?.decimalPlaces }}
        {{ conversion?.baseTransaction?.currency?.code }} to
        {{ conversion?.counterTransaction?.amount | amount: conversion?.counterTransaction?.currency?.decimalPlaces }}
        {{ conversion?.counterTransaction?.currency?.code || conversion?.currency?.code }}
    </ng-container>
    <ng-container *ngSwitchCase="'conversion-accepted'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container> accepted offered conversion
        {{ conversion?.baseTransaction?.amount | amount: conversion?.baseTransaction?.currency?.decimalPlaces }}
        {{ conversion?.baseTransaction?.currency?.code }} to
        {{ conversion?.counterTransaction?.amount | amount: conversion?.counterTransaction?.currency?.decimalPlaces }}
        {{ conversion?.counterTransaction?.currency?.code }}
    </ng-container>

    <!-- Currency -->
    <ng-container *ngSwitchCase="'currency-created'">
        Currency <span class="text-info">{{ currency?.code }}</span> was created by
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: actor }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'currency-updated'">
        Currency <span class="text-info">{{ currency?.code }}</span>
        {{
            data?.patch
                ? 'has been made ' + ($any(data?.patch).value ? '' : 'non-') + $any(data?.patch).path.substr(1, $any(data?.patch).path.length)
                : 'was updated'
        }}
        by <ng-container *ngTemplateOutlet="userTemplate; context: { user: actor }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'currency-deleted'">
        Currency <span class="text-info">{{ currency?.code }}</span> was deleted by
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: actor }"></ng-container>
    </ng-container>

    <!-- Document -->
    <ng-container *ngSwitchCase="'document-created'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: relatedUser }"></ng-container> uploaded a new
        <a [routerLink]="['/admin/accounts/users', relatedUser?.id]" (click)="$event.stopPropagation()">document</a>
    </ng-container>
    <ng-container *ngSwitchCase="'document-updated'">
        A
        <a [routerLink]="['/admin/accounts/users', relatedUser?.id]" (click)="$event.stopPropagation()">document</a>
        of <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: relatedUser }"></ng-container> has been
        <span class="text-green">UPDATED</span>
    </ng-container>
    <ng-container *ngSwitchCase="'document-deleted'">
        A
        <a [routerLink]="['/admin/accounts/users', relatedUser?.id]" (click)="$event.stopPropagation()">document</a>
        of <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: relatedUser }"></ng-container> has been
        <span class="text-danger">DELETED</span>
    </ng-container>

    <!-- Fee -->
    <ng-container *ngSwitchCase="'fee-created'">
        Fee <span class="text-info text-uppercase">{{ fee?.method === 'internal' ? '' : fee?.method }} {{ fee?.type }}</span> of
        <span class="text-green">{{ fee?.currency?.code }}</span> has been set to
        <span class="text-green"
            >{{ fee?.relative | percentage: 3 }} {{ fee?.relative && fee?.fixed ? '+' : '' }}
            <ng-container *ngIf="fee?.fixed">{{ fee?.fixed | amount }} {{ fee?.currency?.code }}</ng-container></span
        >
        <ng-container *ngIf="fee?.user">
            for <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: fee?.user }"></ng-container
        ></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'fee-updated'">
        Fee <span class="text-info text-uppercase">{{ fee?.method === 'internal' ? '' : fee?.method }} {{ fee?.type }}</span> of
        <span class="text-green">{{ fee?.currency?.code }}</span> has been updated to
        <span class="text-green">
            {{ fee?.relative | percentage: 3 }} {{ fee?.relative && fee?.fixed ? '+' : '' }}
            <ng-container *ngIf="fee?.fixed">{{ fee?.fixed | amount }} {{ fee?.currency?.code }}</ng-container>
        </span>
        <ng-container *ngIf="fee?.user">
            for <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: fee?.user }"> </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'fee-deleted'">
        Fee <span class="text-info text-uppercase">{{ fee?.method === 'internal' ? '' : fee?.method }} {{ fee?.type }}</span> of
        <span class="text-green">{{ fee?.currency?.code }}</span> has been deleted
    </ng-container>

    <!-- File -->
    <ng-container *ngSwitchCase="'poi-document-expiry'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container>'s passport
        <span class="text-info">{{ data?.fileId | truncate: false : 7 : '' }}</span> is about to expire in
        <span class="text-warning">{{ data?.reminderType }}</span>
    </ng-container>

    <!-- Messages -->
    <ng-container *ngSwitchCase="'message-sent'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container> has received a
        <span [innerHTML]="data?.confidential ? '<span class=\'text-green\'>CONFIDENTIAL</span> ' : ''"></span> message
        <span class="text-primary">{{ data?.subject }}</span>
    </ng-container>

    <!-- Settings -->
    <ng-container *ngSwitchCase="'login-unblocked'">
        <span class="text-info">{{ data?.ipAddress }}</span> has been unblocked for login
    </ng-container>

    <!-- Transactions -->
    <ng-container *ngSwitchCase="'transaction-created'">
        <ng-container [ngSwitch]="transaction?.type">
            <ng-container *ngSwitchCase="'deposit'">
                <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: counterUser }"></ng-container> created
                <a
                    class="text-green text-uppercase"
                    [routerLink]="['/admin/banking/transactions', transaction?.id]"
                    (click)="$event.stopPropagation()"
                    >{{ transaction?.method }} deposit</a
                >
                of
                <a class="text-green" [routerLink]="['/admin/banking/transactions', transaction?.id]" (click)="$event.stopPropagation()"
                    >{{ transaction?.amount | amount: transaction?.currency?.decimalPlaces }} {{ transaction?.currency?.code }}</a
                >
            </ng-container>
            <ng-container *ngSwitchCase="'transfer'">
                <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container> requested to sent
                <a class="text-green" [routerLink]="['/admin/banking/transactions', transaction?.id]" (click)="$event.stopPropagation()"
                    >{{ transaction?.amount | amount: transaction?.currency?.decimalPlaces }} {{ transaction?.currency?.code }}</a
                >
                to <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: counterUser }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'withdrawal'">
                <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container> created
                <a
                    class="text-green text-uppercase"
                    [routerLink]="['/admin/banking/transactions', transaction?.id]"
                    (click)="$event.stopPropagation()"
                    >{{ transaction?.method }} withdrawal</a
                >
                of
                <a class="text-green" [routerLink]="['/admin/banking/transactions', transaction?.id]" (click)="$event.stopPropagation()"
                    >{{ transaction?.amount | amount: transaction?.currency?.decimalPlaces }} {{ transaction?.currency?.code }}</a
                >
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'transaction-processed'">
        <ng-container [ngSwitch]="transaction?.type">
            <ng-container *ngSwitchCase="'deposit'">
                <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: counterUser }"></ng-container> completed
                <a
                    class="text-green text-uppercase"
                    [routerLink]="['/admin/banking/transactions', transaction?.id]"
                    (click)="$event.stopPropagation()"
                    >{{ transaction?.method }} deposit</a
                >
                of
                <a class="text-green" [routerLink]="['/admin/banking/transactions', transaction?.id]" (click)="$event.stopPropagation()"
                    >{{ transaction?.amount | amount: transaction?.currency?.decimalPlaces }} {{ transaction?.currency?.code }}</a
                >
            </ng-container>
            <ng-container *ngSwitchCase="'transfer'">
                <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container> sent
                <a class="text-green" [routerLink]="['/admin/banking/transactions', transaction?.id]" (click)="$event.stopPropagation()"
                    >{{ transaction?.amount | amount: transaction?.currency?.decimalPlaces }} {{ transaction?.currency?.code }}</a
                >
                to <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: counterUser }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'withdrawal'">
                <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container> completed
                <a
                    class="text-green text-uppercase"
                    [routerLink]="['/admin/banking/transactions', transaction?.id]"
                    (click)="$event.stopPropagation()"
                    >{{ transaction?.method }} withdrawal</a
                >
                of
                <a class="text-green" [routerLink]="['/admin/banking/transactions', transaction?.id]" (click)="$event.stopPropagation()"
                    >{{ transaction?.amount | amount: transaction?.currency?.decimalPlaces }} {{ transaction?.currency?.code }}</a
                >
            </ng-container>
            <ng-container *ngSwitchCase="'mint'">
                <ng-container *ngTemplateOutlet="userTemplate; context: { user: actor }"></ng-container>
                {{ activity.severity === 'success' ? 'generated' : 'destroyed' }}
                <a class="text-green" [routerLink]="['/admin/banking/transactions', transaction?.id]" (click)="$event.stopPropagation()"
                    >{{ transaction?.amount | amount: transaction?.currency?.decimalPlaces }} {{ transaction?.currency?.code }}</a
                >
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'transaction-updated'">
        <ng-container
            *ngTemplateOutlet="userOrAppTemplate; context: transaction?.type === 'deposit' ? { user: counterUser } : { user: baseUser }"
        ></ng-container
        >'s
        <a class="text-green text-uppercase" [routerLink]="['/admin/banking/transactions', transaction?.id]" (click)="$event.stopPropagation()"
            >{{ transaction?.method }} {{ transaction?.type }}</a
        >
        has been set to
        <a
            class="text-uppercase"
            [ngClass]="transaction?.status === 'cancelled' ? 'text-danger' : 'text-green'"
            [routerLink]="['/admin/banking/transactions', transaction?.id]"
            (click)="$event.stopPropagation()"
            >{{ transaction?.status }}</a
        >
    </ng-container>
    <ng-container *ngSwitchCase="'transaction-reverted'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: data?.owner"></ng-container>'s
        <a class="text-green text-uppercase" [routerLink]="['/admin/banking/transactions', transaction?.id]" (click)="$event.stopPropagation()"
            >{{ transaction?.method }} {{ transaction?.type }}</a
        >
        has been
        <a
            class="text-uppercase text-info"
            [routerLink]="['/admin/banking/transactions', data?.revertedTransaction?.id]"
            (click)="$event.stopPropagation()"
            >reverted</a
        >
    </ng-container>

    <!-- User -->
    <ng-container *ngSwitchCase="'user-created'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container> registered successfully
        <ng-container *ngIf="data?.email"
            >using <span class="text-primary">{{ data?.email }}</span></ng-container
        >
    </ng-container>
    <ng-container *ngSwitchCase="'user-approved'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container>
        <span
            [innerHTML]="
                data?.approved
                    ? ' has been <span class=\'text-green\'>APPROVED</span>'
                    : '\'s approval has been <span class=\'text-danger\'>REVERTED</span>'
            "
        ></span>
    </ng-container>
    <ng-container *ngSwitchCase="'user-updated'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container>'s information have been updated
    </ng-container>
    <ng-container *ngSwitchCase="'user-property-updated'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container>'s custom permissions have been overridden
    </ng-container>
    <ng-container *ngSwitchCase="'merchant-permission-updated'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser || relatedUser }"></ng-container>'s merchant permissions have been
        updated
    </ng-container>
    <ng-container *ngSwitchCase="'status-updated'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container>'s account status changed from
        <span class="text-danger text-uppercase">{{ data?.oldValue }}</span> to <span class="text-primary text-uppercase">{{ data?.newValue }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="'email-updated'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container>'s email changed from
        <span class="text-danger">{{ data?.oldValue }}</span> to <span class="text-primary">{{ data?.newValue }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="'password-updated'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container>'s password has been
        <span class="text-primary">CHANGED</span>
    </ng-container>
    <ng-container *ngSwitchCase="'password-restored'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container>'s password has been
        <span class="text-primary">RESTORED</span>
    </ng-container>
    <ng-container *ngSwitchCase="'two-factor-updated'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container> has
        <span [ngClass]="data?.twoFactor ? 'text-green' : 'text-danger'">{{ data?.twoFactor ? 'ENABLED' : 'DISABLED' }}</span> two factor
        authentication
    </ng-container>
    <ng-container *ngSwitchCase="'permission-updated'">
        <ng-container *ngIf="relatedUser; else defaultPermissions">
            Permissions of <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: relatedUser }"></ng-container></ng-container
        ><ng-template #defaultPermissions>Default permissions</ng-template> have been <span class="text-primary">UPDATED.</span>
        <ng-container *ngIf="data?.enabled.length > 0">
            Enabled <span class="text-uppercase">{{ join(data?.enabled) }}</span
            >.</ng-container
        >
        <ng-container *ngIf="data?.disabled.length > 0">
            Disabled <span class="text-uppercase">{{ join(data?.disabled) }}</span></ng-container
        >
    </ng-container>
    <ng-container *ngSwitchCase="'risk-rating-updated'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: relatedUser }"></ng-container>'s risk rating has been updated from
        <span class="text-primary text-uppercase">{{ data?.oldValue }}</span> to <span class="text-primary text-uppercase">{{ data?.newValue }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="'role-updated'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: relatedUser }"></ng-container>'s role has been changed to
        <span class="text-primary text-uppercase">{{ relatedUser?.role || 'None' }}</span>
    </ng-container>

    <!-- UserChangeRequest -->
    <ng-container *ngSwitchCase="'user-change-request-updated'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container> submitted new profile information
    </ng-container>
    <ng-container *ngSwitchCase="'user-change-request-submitted'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container> marked profile as
        <span class="text-green">READY FOR REVIEW</span>
    </ng-container>
    <ng-container *ngSwitchCase="'user-change-request-processed'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: relatedUser }"></ng-container>'s change request has been reviewed
    </ng-container>

    <!-- Wallet Addresses -->
    <ng-container *ngSwitchCase="'wallet-address-generated'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container> generated a new wallet address for
        <span class="text-green text-uppercase">{{ data?.coin }}</span>
    </ng-container>

    <!-- Referrer Created -->
    <ng-container *ngSwitchCase="'referrer-created'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container> has been listed as referrer
    </ng-container>

    <!-- Referrer Updated -->
    <ng-container *ngSwitchCase="'referrer-updated'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container>'s referrer has been set to
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: counterUser }"></ng-container>
    </ng-container>

    <!-- Referrer Deleted -->
    <ng-container *ngSwitchCase="'referrer-deleted'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container> has been removed from referrer list
    </ng-container>

    <!-- Association Updated -->
    <ng-container *ngSwitchCase="'association-updated'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container>'s association has been set to
        <span *ngIf="!counterUser">NONE</span>
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: counterUser }"></ng-container>
    </ng-container>

    <!-- External ID Updated -->
    <ng-container *ngSwitchCase="'external-id-updated'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container>'s External ID has been set to
        <span class="text-green text-uppercase">{{ data?.externalId }}</span>
    </ng-container>

    <!-- Api token -->
    <ng-container *ngSwitchCase="'api-token-created'">
        API token <span class="text-info">{{ data?.publicKey }}</span> has been created for
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: data!.user }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'api-token-revoked'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: data!.user }"></ng-container>'s API token
        <span class="text-info">{{ data?.publicKey }}</span> has been revoked
    </ng-container>

    <!-- Account Bill Charged -->
    <ng-container *ngSwitchCase="'account-bill-charged'">
        <ng-container *ngIf="data?.amountBilled; else newAccountBill">
            <div *ngIf="data?.amountBilled > 0; else exempted">
                <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container> has been charged
                <span class="text-info">{{ data?.amountBilled | amount }} {{ data?.currencyCode }} </span>
                for the monthly account fee of {{ data?.invoiceDate | date: 'MMMM YYYY' }}
            </div>
        </ng-container>
        <ng-template #newAccountBill>
            <div *ngIf="data?.billAmount > 0; else exempted">
                <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container> has been charged
                <span class="text-info">{{ data?.settledAmount | amount }} {{ data?.settledCurrencyCode }} </span>
                <span *ngIf="data?.settledCurrencyCode !== 'USD'" class="text-medium font-sm"
                    >({{ data?.billAmount | amount }} {{ data?.billCurrencyCode }})</span
                >
                for the monthly account fee of
                {{ data?.invoiceDate | date: 'MMMM YYYY' }}
            </div>
        </ng-template>
        <ng-template #exempted>
            <ng-container *ngTemplateOutlet="userTemplate; context: { user: baseUser }"></ng-container> has been exempted for the monthly account fee
            of {{ data?.invoiceDate | date: 'MMMM YYYY' }}
        </ng-template>
    </ng-container>

    <!-- Allocation Created -->
    <ng-container *ngSwitchCase="'allocation-created'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: baseUser }"></ng-container> created an allocation of
        {{ data?.allocation?.baseTransaction?.amount | amount }} {{ data?.allocation?.baseTransaction?.currency?.code }}
    </ng-container>

    <!-- Card Top Up -->
    <ng-container *ngSwitchCase="'card-topup'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: data?.card?.cardholder }"></ng-container> has topped up
        <span class="text-success">{{ data?.amount | amount }} {{ data?.currencyCode }}</span> to
        <span class="text-uppercase text-info">{{ data?.card?.type }}</span> card {{ data?.card?.maskedPan }} by
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: actor }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'card-topup-failed'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: data?.card?.cardholder }"></ng-container> top up for
        <span class="text-uppercase text-primary">{{ data?.card?.type }}</span> card <span>{{ data?.card?.maskedPan }}</span> failed due to
        <span class="text-uppercase text-danger">{{ data?.reason }}</span>
    </ng-container>

    <!-- Card Unload -->
    <ng-container *ngSwitchCase="'card-unload'">
        <ng-container *ngTemplateOutlet="userTemplate; context: { user: data?.card?.cardholder }"></ng-container> has unloaded
        <span class="text-success">{{ data?.amount | amount }} {{ data?.currencyCode }}</span> from
        <span class="text-uppercase text-info">{{ data?.card?.type }}</span> card {{ data?.card?.maskedPan }}
    </ng-container>
</ng-container>

<!-- Helper -->
<ng-template let-user="user" #userTemplate>
    <a [routerLink]="['/admin/accounts/users', user?.id || undefined]" (click)="$event.stopPropagation()">{{ user?.name }}</a>
</ng-template>
<ng-template let-user="user" #userOrAppTemplate>
    <ng-container *ngIf="user; else app">
        <a [routerLink]="['/admin/accounts/users', user?.id || undefined]" (click)="$event.stopPropagation()">{{ user?.name }}</a>
    </ng-container>
    <ng-template #app>
        {{ displayName }}
    </ng-template>
</ng-template>
