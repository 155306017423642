<section class="container-fluid">
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
            <span>{{ 'common.funds-under-management' | translate }}</span>
            <div>
                <button type="button" class="btn btn-primary btn-sm" (click)="requestExport()">
                    <fa-icon [icon]="faDownload" class="me-1"></fa-icon> {{ 'common.export' | translate }}
                </button>
            </div>
        </div>
        <div class="card-body">
            <div class="row no-gutters d-flex justify-content-between align-items-center mb-3">
                <div class="col-lg-9 mb-2 mb-lg-0">
                    <div class="input-group">
                        <span class="input-group-text"><fa-icon [icon]="faSearch"></fa-icon></span>
                        <input
                            type="text"
                            name="searchText"
                            (ngModelChange)="searchEvent.next()"
                            [placeholder]="'common.search' | translate"
                            class="form-control"
                            [(ngModel)]="search"
                        />
                        <div class="ms-3 d-flex align-items-center">
                            <span
                                >{{ count | number }}
                                {{ (count === 1 ? 'common.entry-found' : 'common.entries-found') | translate | lowercase }}</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 d-flex justify-content-between align-items-center mb-2 mb-lg-0 pe-0 pe-lg-5 pe-xl-3">
                    <div class="col p-0 ms-0 ms-lg-3">
                        <ng-select
                            class="min-width-sm"
                            name="type"
                            [placeholder]="'common.select-filter' | translate"
                            [items]="currencies"
                            [ngModel]="currency"
                            (change)="onCurrencyChanged($event)"
                            [clearable]="false"
                            [searchable]="false"
                        >
                            <ng-template ng-label-tmp let-item="item">
                                {{ item.currencyCode }}
                                <span class="small text-muted">{{ item.sum | amount: item.decimalPlaces }}</span></ng-template
                            >
                            <ng-template ng-option-tmp let-item="item"
                                >{{ item.currencyCode }}
                                <span class="float-end small text-muted"> {{ item.sum | amount: item.decimalPlaces }} {{ item.currencyCode }}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="d-flex align-items-center justify-content-end">
                        <ng-select
                            class="me-2"
                            [(ngModel)]="limit"
                            dropdownPosition="auto"
                            (change)="select(1)"
                            [items]="limitOpts"
                            [placeholder]="'common.show-entries-per-page' | translate"
                            [clearable]="false"
                            [searchable]="false"
                            required
                        >
                            <ng-template ng-label-tmp let-item="item">{{ 'common.show-entries' | translate: { value: item } }}</ng-template>
                            <ng-template ng-option-tmp let-item="item">{{ item }} {{ 'common.entries' | translate }}</ng-template>
                        </ng-select>
                        <ngb-pagination
                            [(page)]="page"
                            [collectionSize]="count"
                            (pageChange)="select()"
                            [pageSize]="limit"
                            [maxSize]="5"
                            [ellipses]="false"
                            [rotate]="true"
                            [boundaryLinks]="true"
                        >
                        </ngb-pagination>
                    </div>
                </div>
            </div>
            <div class="table-responsive position-relative">
                <table class="table table-hover table-bordered table-data">
                    <thead class="table-light">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{{ 'common.name' | translate }}</th>
                            <th scope="col">{{ 'common.balance' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="itemsStream | async; let items">
                            <tr class="e2e-fum-wallet" *ngFor="let wallet of items">
                                <th scope="row" [width]="'1%'">
                                    <uuid [value]="wallet.id"></uuid>
                                </th>
                                <td>
                                    <user-display [user]="wallet.user"></user-display>
                                </td>
                                <td>
                                    <div class="text-primary">
                                        {{ wallet.balance | amount: wallet.currency.decimalPlaces }} {{ wallet.currency.code }}
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="items.length === 0">
                                <td colspan="8" class="text-center">{{ 'common.no-entries-found' | translate }}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <ng-container *ngIf="isFetching | async">
                    <circle-spinner></circle-spinner>
                </ng-container>
            </div>
        </div>
    </div>
</section>
