<div class="container">
    <div class="row no-gutters">
        <div class="footer-left col-lg-8">&copy; {{ year }} Aerapass Ltd. - All rights reserved.</div>
        <div class="footer-right col-lg-16">
            <ul>
                <li class="d-flex align-items-center" ngbDropdown placement="top-left" container="body">
                    <button type="button" class="btn btn-transparent" ngbDropdownToggle>
                        <span>{{ languageService.getCurrentLanguage() | uppercase }}</span>
                    </button>
                    <div ngbDropdownMenu>
                        <button *ngFor="let lang of languageService.languages" ngbDropdownItem (click)="languageService.changeLanguage(lang)">
                            <span>{{ languageService.getCountryEmoji(lang) }}&nbsp;</span> {{ languageService.getLanguageName(lang) }}
                        </button>
                    </div>
                </li>
                <li class="d-flex align-items-center">
                    <a [routerLink]="['/fees']" class="text-muted">{{ 'common.fee-schedule' | translate | titlecase }}</a>
                </li>
                <li class="d-flex align-items-center">
                    <a [href]="helpCenterUrl" class="text-muted" target="_blank" rel="noopener">{{ 'footer.help-center' | translate }}</a>
                </li>
                <li class="d-flex align-items-center">
                    <a [routerLink]="['/terms']" class="text-muted">{{ 'common.terms-conditions' | translate }}</a>
                </li>
                <li class="d-flex align-items-center">
                    <a [routerLink]="['/privacy']" class="text-muted">{{ 'common.data-privacy-notice' | translate | titlecase }}</a>
                </li>
            </ul>
        </div>
    </div>
</div>
