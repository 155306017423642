<table *ngIf="cardProgram?.provider === 'nium' && niumCardFees" class="table table-striped table-borderless mb-0">
    <tbody>
        <tr>
            <th scope="row">{{ 'common.topup-fee' | translate }}</th>
            <td class="text-end">
                {{ niumCardFees.topupFee }}
            </td>
        </tr>
        <tr>
            <th scope="row">{{ 'common.atm-fee' | translate }}</th>
            <td class="text-end">
                {{
                    niumCardFees.atmFee
                        ? niumCardFees.atmFee !== '0'
                            ? (niumCardFees.atmFee | amount: 2) + ' USD'
                            : ('common.free' | translate | uppercase)
                        : ('common.unknown' | translate)
                }}
            </td>
        </tr>
        <tr>
            <th scope="row">{{ 'common.atm-decline-fee' | translate }}</th>
            <td class="text-end">
                {{
                    niumCardFees.atmDeclineFee
                        ? niumCardFees.atmDeclineFee !== '0'
                            ? (niumCardFees.atmDeclineFee | amount: 2) + ' USD'
                            : ('common.free' | translate | uppercase)
                        : ('common.unknown' | translate)
                }}
            </td>
        </tr>
        <tr>
            <th scope="row">FX</th>
            <td class="text-end">
                {{
                    niumCardFees.fx
                        ? niumCardFees.fx !== '0'
                            ? (niumCardFees.fx | amount: 2) + ' %'
                            : ('common.free' | translate | uppercase)
                        : ('common.unknown' | translate)
                }}
            </td>
        </tr>
        <tr>
            <th scope="row">{{ 'common.decline-transaction' | translate }}</th>
            <td class="text-end">
                {{
                    niumCardFees.declineTransaction
                        ? (niumCardFees.declineTransaction | amount: 2) !== '0'
                            ? niumCardFees.declineTransaction + ' USD'
                            : ('common.free' | translate | uppercase)
                        : ('common.unknown' | translate)
                }}
            </td>
        </tr>
        <tr>
            <th scope="row">{{ 'common.replacement-fee' | translate }}</th>
            <td class="text-end">
                {{
                    niumCardFees.replacementFee
                        ? niumCardFees.replacementFee !== '0'
                            ? (niumCardFees.replacementFee | amount: 2) + ' USD'
                            : ('common.free' | translate | uppercase)
                        : ('common.unknown' | translate)
                }}
            </td>
        </tr>
        <tr *ngIf="niumCardFees.ecomFee && niumCardFees.ecomFee !== '0'">
            <th scope="row">{{ 'common.ecommerce-fee' | translate }}</th>
            <td class="text-end">
                {{ (niumCardFees.ecomFee | amount: 2) + ' USD' }}
            </td>
        </tr>
        <tr *ngIf="niumCardFees.posFee && niumCardFees.posFee !== '0'">
            <th scope="row">{{ 'common.pos-fee' | translate }}</th>
            <td class="text-end">
                {{ (niumCardFees.posFee | amount: 2) + ' USD' }}
            </td>
        </tr>
        <tr>
            <th scope="row">{{ 'common.unload-fee' | translate }}</th>
            <td class="text-end">
                {{ niumCardFees.unloadFundsFee }}
            </td>
        </tr>
        <tr>
            <th scope="row">{{ 'common.max-balance' | translate }}</th>
            <td class="text-end">
                {{ niumCardFees.annualLoadingLimit ? (niumCardFees.annualLoadingLimit | amount: 2) + ' USD' : ('common.unlimited' | translate) }}
            </td>
        </tr>
        <tr>
            <th scope="row">{{ 'common.monthly-spending-limit' | translate }}</th>
            <td class="text-end">
                {{ (niumCardFees.monthlySpendingLimit | amount: 2) + ' USD' }}
            </td>
        </tr>
        <tr>
            <th scope="row">{{ 'common.per-transaction-spending-limit' | translate }}</th>
            <td class="text-end">
                {{ (niumCardFees.dailyPosEcomLimit | amount: 2) + ' USD' }}
            </td>
        </tr>
        <tr>
            <th scope="row">{{ 'common.daily-atm-limit' | translate }}</th>
            <td class="text-end">
                {{ (niumCardFees.dailyAtmLimit | amount: 2) + ' USD' }}
            </td>
        </tr>
    </tbody>
</table>
<table *ngIf="cardProgram?.provider === 'sinopay'" class="table table-striped table-borderless mb-0">
    <tbody>
        <tr>
            <td colspan="2"><h5>Fees</h5></td>
        </tr>
        <tr>
            <th scope="row">{{ 'common.topup-fee' | translate }}</th>
            <td class="text-end">
                {{ sinopayCardFees.topupFee }}
            </td>
        </tr>
        <tr>
            <th scope="row">{{ 'card-fee-schedule.withdrawal-fee' | translate }}</th>
            <td class="text-end">{{ sinopayCardFees.withdrawalFee | amount: 2 }} %</td>
        </tr>
        <tr>
            <th scope="row">{{ 'card-fee-schedule.balance-inquiry-fee' | translate }}</th>
            <td class="text-end">{{ sinopayCardFees.balanceInquiryFee | amount: 2 }} SGD</td>
        </tr>
        <tr>
            <th scope="row">{{ 'card-fee-schedule.renewal-fee' | translate }}</th>
            <td class="text-end">{{ sinopayCardFees.renewalFee | amount: 2 }} SGD</td>
        </tr>
        <tr>
            <td colspan="2"><h5>Limits</h5></td>
        </tr>
        <tr>
            <th scope="row">{{ 'common.max-balance' | translate }}</th>
            <td class="text-end">{{ sinopayCardFees.maxBalance | amount: 2 }} SGD</td>
        </tr>
        <tr>
            <th scope="row">{{ 'card-fee-schedule.min-withdrawal' | translate }}</th>
            <td class="text-end">{{ sinopayCardFees.minimumWithdrawal | amount: 2 }} SGD</td>
        </tr>
        <tr>
            <th scope="row">{{ 'card-fee-schedule.max-withdrawal' | translate }}</th>
            <td class="text-end">{{ sinopayCardFees.maximumWithdrawal | amount: 2 }} SGD</td>
        </tr>
        <tr>
            <th scope="row">{{ 'card-fee-schedule.daily-limit' | translate }}</th>
            <td class="text-end">{{ sinopayCardFees.dailyWithdrawalLimit | amount: 2 }} SGD</td>
        </tr>
        <tr>
            <th scope="row">{{ 'card-fee-schedule.monthly-limit' | translate }}</th>
            <td class="text-end">{{ sinopayCardFees.monthlyWithdrawalLimit | amount: 2 }} SGD</td>
        </tr>
        <tr>
            <th scope="row">{{ 'card-fee-schedule.topup-limit' | translate }}</th>
            <td class="text-end">
                <p class="mb-0">{{ sinopayCardFees.topupLimit | amount: 2 }} SGD</p>
                <small class="text-muted">{{ sinopayCardFees.allowedMonthlyTopup }} {{ 'card-fee-schedule.topup-per-month' | translate }}</small>
            </td>
        </tr>
        <tr>
            <th scope="row">{{ 'card-fee-schedule.annual-topup-limit' | translate }}</th>
            <td class="text-end">{{ sinopayCardFees.annualTopupLimit | amount: 2 }} SGD</td>
        </tr>
    </tbody>
</table>
<table *ngIf="cardProgram?.provider === 'quicko'" class="table table-striped table-borderless mb-0">
    <tbody>
        <tr>
            <td colspan="2"><h5>Fees</h5></td>
        </tr>
        <tr>
            <th scope="row">{{ 'common.topup-fee' | translate }}</th>
            <td class="text-end text-success">FREE</td>
        </tr>
        <tr>
            <th scope="row">{{ 'card-fee-schedule.withdrawal-fee' | translate }}</th>
            <td class="text-end text-success">FREE</td>
        </tr>
        <tr>
            <th scope="row">{{ 'card-fee-schedule.balance-inquiry-fee' | translate }}</th>
            <td class="text-end text-success">FREE</td>
        </tr>
        <tr>
            <th scope="row">FX</th>
            <td class="text-end">1%</td>
        </tr>
        <ng-container *ngIf="!!card?.currency">
            <tr>
                <td colspan="2"><h5>Limits</h5></td>
            </tr>
            <tr>
                <th scope="row">{{ 'common.max-card-balance' | translate }}</th>
                <td class="text-end">500,000 {{ card?.currency?.code }}</td>
            </tr>
            <tr>
                <th scope="row">{{ 'card-fee-schedule.transaction-limit' | translate }}</th>
                <td class="text-end">500,000 {{ card?.currency?.code }}</td>
            </tr>
            <tr>
                <th scope="row">{{ 'card-fee-schedule.atm-limit' | translate }}</th>
                <td class="text-end">10,000 {{ card?.currency?.code }}</td>
            </tr>
            <tr>
                <th scope="row">{{ 'card-fee-schedule.ecommerce-limit' | translate }}</th>
                <td class="text-end">100,000 {{ card?.currency?.code }}</td>
            </tr>
        </ng-container>
    </tbody>
</table>
