export function stripZeros(value: string): string {
    if (!value.includes('.')) {
        return value // No decimal point, return as is
    }

    // Remove trailing zeros using regex
    value = value.replace(/(\.0*|(\.\d*?[1-9])0+)$/, '$2')

    // If the result ends with a decimal point, remove it
    return value.endsWith('.') ? value.slice(0, -1) : value
}
