import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { Observable, of } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, finalize, switchMap } from 'rxjs/operators'
import { IAddress } from '../api-interfaces'
import { transformAddress } from '../common.mixin'
import { countriesSortedByName } from '../countries'

@Component({
    selector: 'address-form',
    templateUrl: 'address-form.component.html',
})
export class AddressFormComponent implements OnChanges {
    @Input()
    public address: IAddress
    @Output()
    public readonly addressChange = new EventEmitter<IAddress>()
    @Input()
    public prefix: string
    /** If true, all address fields will be merged into one autocomplete field */
    @Input()
    public compact: boolean
    @Input()
    public isLarge = false
    /* Removes most required constraints on inputs as the admin route allows many blank fields */
    @Input()
    public requireAll = true
    @Input()
    public disabled = false
    @Input()
    public showToggleButton = true
    @ViewChild('addressForm')
    public form: NgForm
    public countriesSortedByName = countriesSortedByName
    public submitted = false
    /** Street from places api. Street is stored here so that it can be assigned to the this.user.addressLine1 on submit. */
    public addressLine1: { name: string | null } | { formatted_address: string } | null
    public placesAutocomplete = new EventEmitter<string>()
    public placesStream: Observable<any[]> = this.placesAutocomplete.pipe(
        distinctUntilChanged(),
        debounceTime(200),
        switchMap(text =>
            text && text.length > 2
                ? this.http.get<any[]>('/places', {
                      params: {
                          search: text,
                      },
                  })
                : of([])
        ),
        catchError(() => of([]))
    )

    constructor(
        public translate: TranslateService,
        private http: HttpClient
    ) {}

    public ngOnChanges(): void {
        if (!this.address) {
            this.address = {} as any
            this.addressChange.emit(this.address)
        }
        this.transformAddress()
    }

    public submit(): void {
        this.submitted = true
    }

    public isValid(): boolean {
        return !!this.form.valid
    }

    public setAddress(place: any): void {
        if (place && place.structured_formatting && place.structured_formatting.main_text) {
            this.addressLine1 = place.structured_formatting.main_text
        }
        if (place && place.place_id) {
            this.disabled = true
            this.http
                .get<any>('/places/' + place.place_id)
                .pipe(
                    finalize(() => {
                        this.disabled = false
                    })
                )
                .subscribe(details => {
                    const getType = (type: string) =>
                        details.address_components.find((component: any) => component.types.includes(type))
                    this.address.addressLine1 = details.name || null
                    this.address.city = (getType('locality') && getType('locality').long_name) || null
                    this.address.zipCode = (getType('postal_code') && getType('postal_code').long_name) || null
                    this.address.state =
                        (getType('administrative_area_level_1') && getType('administrative_area_level_1').long_name) ||
                        null
                    this.address.country = (getType('country') && getType('country').short_name) || null
                    this.transformAddress()
                    this.addressChange.emit(this.address)
                })
        } else if (place && place.name) {
            this.address.addressLine1 = place.name
            this.addressChange.emit(this.address)
        }
    }

    public transformAddress(): void {
        this.addressLine1 = transformAddress(this.address, this.compact)
    }
}
