import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import BigNumber from 'bignumber.js'
import { cloneDeep } from 'lodash'
import { Observable, of, Subject, Subscription } from 'rxjs'
import { finalize, flatMap, map, switchMap, tap } from 'rxjs/operators'
import { getFeeType } from 'src/app/common/models/accounting/fee.model'
import { ICurrency, Paginated, RPCResult, Transaction, WithdrawalProvider } from '../../common/api-interfaces'
import { isZero } from '../../common/common.mixin'
import { Currency } from '../../common/models/accounting/currency.model'
import { SecurityCheckService } from '../../common/security-check/security-check.service'
import { FeeService } from '../../common/services/accounting/fee.service'
import { ToastrService } from '../../common/services/toastr.service'
import { TransactionService } from '../../common/services/transaction.service'
import { calculateCreditableAmount, calculateFee } from '../../common/transactions.mixins'

@Component({
    selector: 'transaction-process',
    templateUrl: 'transaction-process.component.html',
})
export class TransactionProcessComponent implements OnInit, OnDestroy {
    @Input()
    public transaction: Transaction
    @Output()
    public readonly onSave = new EventEmitter<void>()
    @ViewChild('transactionForm')
    public transactionForm: NgForm
    public copy: Transaction = {} as Transaction

    public currencies: ICurrency[]
    public isSaving = false
    public isSettleInDifferentCurrency = false
    public isZero = isZero
    public calculateFee = calculateFee
    public calculateCreditableAmount = calculateCreditableAmount
    public withdrawalProviders: { value: WithdrawalProvider; label: string }[] = [
        { value: 'none', label: 'Manual' },
        { value: 'tm2', label: 'TM2' },
        { value: 'thunes', label: 'Thunes' },
    ]
    public selectedProvider: WithdrawalProvider = 'none'

    // Thunes
    public thunesPayload = {
        documents: {},
        identifier: {},
        receiver: {},
    }

    public fetchFeeEvent = new Subject<void>()
    private fetchEvent = new Subject<void>()
    private subscriptions = new Subscription()

    constructor(
        private http: HttpClient,
        public toastr: ToastrService,
        public activeModal: NgbActiveModal,
        public transactionService: TransactionService,
        private securityCheckService: SecurityCheckService,
        private feeService: FeeService,
        public translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            this.fetchEvent
                .pipe(
                    switchMap(() => this.fetchCurrencies()),
                    flatMap(() => (this.transaction && this.transaction.id ? this.fetch() : of(undefined)))
                )
                .subscribe(response => {
                    if (response) {
                        Object.assign(this.transaction, response)
                    }
                    this.transaction.currency = this.currencies.find(
                        currency => currency.code === this.transaction.currency.code
                    )!
                    this.transaction.amount = new BigNumber(this.transaction.amount).toFixed(
                        this.transaction.currency.decimalPlaces,
                        1
                    )
                    if (this.transaction.requestedCurrency) {
                        this.transaction.requestedCurrency = this.currencies.find(
                            currency => currency.code === this.transaction.requestedCurrency!.code
                        )!
                    }
                    if (this.transaction.requestedAmount) {
                        this.transaction.requestedAmount = new BigNumber(this.transaction.requestedAmount).toFixed(
                            this.transaction.currency.decimalPlaces,
                            1
                        )
                    }
                    this.copy = cloneDeep(this.transaction)
                })
        )
        this.subscriptions.add(
            this.fetchFeeEvent
                .pipe(
                    switchMap(() =>
                        this.feeService.fetch({
                            type: getFeeType(this.transaction.type),
                            method: this.transaction.method,
                            currency: this.transaction.currency,
                        })
                    )
                )
                .subscribe(fee => {
                    if (fee) {
                        this.copy.fixedFee = fee.fixed
                            ? Currency.convert(fee.fixed, fee.currency, this.copy.currency)
                            : '0'
                        this.copy.relativeFee = fee.relative ? fee.relative : '0'
                    }
                })
        )

        this.copy = cloneDeep(this.transaction)
        this.fetchEvent.next()
        this.fetchFeeEvent.next()
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public fetch(): Observable<Transaction> {
        return this.http.get<Transaction>(`/transactions/${this.transaction.id}`)
    }

    public fetchCurrencies(): Observable<void> {
        return this.http.get<Paginated<ICurrency>>('/currencies', { params: { limit: 100 + '' } }).pipe(
            tap(response => {
                this.currencies = response.data
            }),
            map(() => undefined)
        )
    }

    public submit(): void {
        if (this.transactionForm.invalid) {
            throw new Error(this.translate.instant('common.form-invalid'))
        }
        this.subscriptions.add(
            this.securityCheckService.getScopePermission('banking').subscribe(() => {
                this.isSaving = true
                this.http
                    .put<Transaction>(`/transactions/${this.transaction.id}`, this.copy)
                    .pipe(
                        tap(transaction => {
                            this.copy = transaction
                        }),
                        flatMap(transaction => this.process(transaction)),
                        tap(response => {
                            if (response) {
                                if (this.transaction.type === 'withdrawal' && this.selectedProvider !== 'none') {
                                    Object.assign(this.copy, response)
                                } else {
                                    Object.assign(this.copy, (response as RPCResult<Transaction>).result)
                                }
                            }
                        }),
                        finalize(() => {
                            this.isSaving = false
                        })
                    )
                    .subscribe(() => {
                        this.toastr.success(this.translate.instant('common.transaction-processed'))
                        this.transactionForm.reset()
                        this.onSave.emit()
                        this.transactionService.changeEvent.emit()
                    })
            })
        )
    }

    public process(transaction: Transaction): Observable<RPCResult<Transaction> | Transaction> {
        if (transaction.type === 'withdrawal' && this.selectedProvider !== 'none') {
            const body = this.selectedProvider === 'thunes' ? this.thunesPayload : {}
            return this.http.post<Transaction>(`/admin/transactions/${transaction.id}/process-withdrawal`, {
                provider: this.selectedProvider,
                ...body,
            })
        }
        return this.http.post<RPCResult<Transaction>>(
            `/transactions/${transaction.id}`,
            {
                id: Math.floor(Math.random() * 10000),
                jsonrpc: '2.0',
                method: 'process',
            },
            {
                headers: new HttpHeaders({ 'Content-Type': 'application/json-rpc' }),
            }
        )
    }

    public convertAmount(): string {
        if (this.copy.receivedCurrency && this.copy.currency) {
            return Currency.calculateExchangeRate(this.copy.receivedCurrency, this.copy.currency)
                .times(this.copy.receivedAmount ? this.copy.receivedAmount : 0)
                .toFixed(this.copy.currency.decimalPlaces, 1)
        }
        return ''
    }

    public onSettleInDifferentCurrency(): void {
        if (this.isSettleInDifferentCurrency) {
            this.copy.receivedAmount = this.copy.amount
            this.copy.receivedCurrency = this.copy.currency
        } else {
            this.copy.amount = this.transaction.amount
            this.copy.currency = this.transaction.currency
        }
    }

    public calculateExchangeRate(): string {
        if (!this.copy.receivedAmount) {
            return '0'
        }
        return new BigNumber(this.copy.amount!).div(this.copy.receivedAmount).toString()
    }

    public calculateActualExchangeRate(): string {
        if (!this.copy.receivedCurrency) {
            return '0'
        }
        return new BigNumber(this.copy.currency!.exchangeRate).div(this.copy.receivedCurrency.exchangeRate).toString()
    }

    public changeProvider(provider: WithdrawalProvider): void {
        this.selectedProvider = provider
    }
}
