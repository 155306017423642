import { isPlatformBrowser } from '@angular/common'
import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { Subscription, interval, merge } from 'rxjs'
import { filter, startWith, switchMap } from 'rxjs/operators'
import { LogoutTimerService } from './app/core/logout-timer/logout-timer.service'
import { MenuService } from './app/core/menu/menu.service'
import { GoogleAnalyticsService } from './common/services/google-analytics.service'
import { IntercomService } from './common/services/intercom.service'
import { SessionService, UserEventType } from './common/services/session.service'
import { ViewportService } from './common/services/viewport.service'
import { CurrenciesService } from './store/currencies/currencies.service'
import { WalletsService } from './store/wallets/wallets.service'

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    private polling: Subscription
    private subscriptions = new Subscription()
    private unlistenShowIntercom: () => void
    private unlistenHideIntercom: MutationObserver

    constructor(
        public session: SessionService,
        public menuService: MenuService,
        public translate: TranslateService,
        public renderer: Renderer2,
        private intercom: IntercomService,
        private analytics: GoogleAnalyticsService,
        private logoutTimer: LogoutTimerService,
        private viewport: ViewportService,
        private router: Router,
        private ngbModal: NgbModal,
        private currenciesService: CurrenciesService,
        private walletsService: WalletsService,
        @Inject(PLATFORM_ID) private platformId: Record<string, any>
    ) {
        translate.addLangs(['en', 'kr', 'cn', 'fr', 'jp'])
        translate.setDefaultLang('en')

        let lang = 'en'

        const lastLanguageUsed = localStorage.getItem('lang')
        if (lastLanguageUsed) {
            lang = lastLanguageUsed
        } else {
            const browserLang = translate.getBrowserLang()
            lang = browserLang?.match(/en|kr|cn|fr|jp/) ? browserLang : 'en'
        }

        translate.use(lang)
    }

    public async ngOnInit(): Promise<void> {
        if (isPlatformBrowser(this.platformId)) {
            this.logoutTimer.init()
            this.intercom.boot()
            this.analytics.init()
            this.viewport.update()
        }
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' as any })
            if (this.ngbModal.hasOpenModals()) {
                this.ngbModal.dismissAll()
            }
        })
        this.subscriptions.add(
            this.session.userEvent.subscribe(event => {
                if (event.type === UserEventType.LOGIN) {
                    this.polling = merge(
                        interval(1000 * 10).pipe(
                            startWith(0),
                            switchMap(() => this.walletsService.fetchAsObservable())
                        ),
                        interval(1000 * 60 * 2).pipe(
                            startWith(0),
                            switchMap(() => this.currenciesService.fetchAsObservable())
                        )
                    ).subscribe(() => undefined)
                } else if (event.type === UserEventType.LOGOUT) {
                    if (this.polling && !this.polling.closed) {
                        this.polling.unsubscribe()
                    }
                    if (!this.router.isActive('/login', false) && !this.router.getCurrentNavigation()) {
                        this.router.navigate(['/login'])
                    }
                }
            })
        )
        // TODO: remove when session service has been refactored
        if (this.session.hasValidRefreshToken()) {
            this.session.update().subscribe(() => undefined)
        }

        this.unlistenHideIntercom = new MutationObserver(mutations => {
            if (
                mutations.some(
                    mutationRecord =>
                        (mutationRecord.addedNodes[0] as any)?.className.includes('right') &&
                        (mutationRecord.addedNodes[0] as any)?.className.includes('modal')
                )
            ) {
                this.intercom.hide()
            }
        })

        this.unlistenHideIntercom.observe(window.document.body, {
            childList: true,
        })

        this.unlistenShowIntercom = this.renderer.listen('body', 'DOMNodeRemoved', event => {
            if (
                event.target?.className?.toString().includes('modal') &&
                event.target?.className?.toString().includes('right')
            ) {
                this.intercom.show()
            }
        })
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
        this.unlistenHideIntercom.disconnect()
        this.unlistenShowIntercom()
    }

    @HostListener('window:resize')
    public onResize(): void {
        this.viewport.update()
    }

    public showHeader(): boolean {
        return (
            this.session.isAuthenticated() &&
            !['/login', '/register', '/p', '/migration', '/signatory'].some(state => this.router.isActive(state, false))
        )
    }

    public showSidebar(): boolean {
        return (
            this.session.isAuthenticated() &&
            !['/login', '/register', '/p', '/migration', '/signatory'].some(state => this.router.isActive(state, false))
        )
    }

    public showFooter(): boolean {
        return (
            this.session.isAuthenticated() &&
            ![
                '/login',
                '/register',
                '/admin',
                '/back-office',
                '/back-office-v2',
                '/p',
                '/migration',
                '/signatory',
            ].some(state => this.router.isActive(state, false))
        )
    }
}
