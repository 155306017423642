import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { countriesSortedByName } from 'src/app/common/countries'

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    public languages = ['cn', 'en', 'fr', 'de', 'el', 'it', 'jp', 'kr', 'es']

    private languageToCountryMap = new Map<string, string>([
        ['en', 'US'],
        ['kr', 'KR'],
        ['cn', 'CN'],
        ['jp', 'JP'],
        ['fr', 'FR'],
        ['es', 'ES'],
        ['de', 'DE'],
        ['it', 'IT'],
        ['el', 'GR'],
    ])

    private languageNames = new Map<string, string>([
        ['en', 'English'],
        ['kr', 'Korean'],
        ['cn', 'Chinese'],
        ['jp', 'Japanese'],
        ['fr', 'French'],
        ['de', 'German'],
        ['es', 'Spanish'],
        ['it', 'Italian'],
        ['el', 'Greek'],
    ])

    constructor(private translate: TranslateService) {}

    public getCurrentLanguage(): string {
        return this.translate.currentLang
    }

    public changeLanguage(language: string): void {
        this.translate.use(language)
        localStorage.setItem('lang', language)
    }

    public getCountryEmoji(lang: string): string {
        const alpha2 = this.languageToCountryMap.get(lang)
        return alpha2 ? countriesSortedByName.find(country => country.alpha2 === alpha2)?.emoji || '' : ''
    }

    public getLanguageName(lang: string): string {
        return this.languageNames.get(lang) || ''
    }
}
