import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Observable, of, throwError, timer } from 'rxjs'
import { catchError, flatMap, retryWhen } from 'rxjs/operators'
import { openKycLimitReachedModal } from './kyc-limit-reached-modal/kyc-limit-reached-modal.component'
import { openNoAccessModal } from './no-access-modal/no-access-modal.component'
import { LogoutService } from './services/logout.service'
import { SessionService } from './services/session.service'
import { ToastrService } from './services/toastr.service'

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    public static readonly MAX_RETRY_ATTEMPTS = 3
    constructor(
        private toastr: ToastrService,
        private logoutService: LogoutService,
        private modal: NgbModal,
        private session: SessionService
    ) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const shouldLogOut = !(req.headers.get('X-No-Logout') && JSON.parse(req.headers.get('X-No-Logout')!))
        const ignoreErrorHandling = !!(
            req.headers.get('X-Ignore-Error-Handling') && JSON.parse(req.headers.get('X-Ignore-Error-Handling')!)
        )
        const suppressToast = req.headers.get('X-No-Toast') && JSON.parse(req.headers.get('X-No-Toast')!)
        return next
            .handle(
                req.clone({
                    headers: req.headers.delete('X-No-Logout').delete('X-Ignore-Error-Handling').delete('X-No-Toast'),
                })
            )
            .pipe(
                retryWhen(attempts =>
                    attempts.pipe(
                        flatMap((error, attempt) => {
                            // only retry 503 maintenance errored requests
                            if (error.status !== 503) {
                                return throwError(error)
                            }
                            const retryAttempt = attempt + 1
                            // if maximum number of retries have been exceeded, throw an error
                            if (retryAttempt > ErrorHandlerInterceptor.MAX_RETRY_ATTEMPTS) {
                                return throwError(error)
                            }
                            // retry after increasing delay, 1000ms, 2000ms, 3000ms, abort
                            // eslint-disable-next-line no-console
                            console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * 1000}ms`)
                            return timer(retryAttempt * 1000)
                        })
                    )
                ),
                catchError(err => {
                    if (!ignoreErrorHandling && err instanceof HttpErrorResponse) {
                        const response = err as HttpErrorResponse
                        let error: Error & { errors?: string[] }
                        if (response.status === -1) {
                            error = new Error('Connection refused')
                        } else if (response.status === 0) {
                            error = new Error('Request failed')
                        } else if (response.error) {
                            error = response.error
                        } else if (response.message) {
                            error = new Error(response.message)
                        } else {
                            error = new Error(response.statusText || 'Error ' + response.status)
                        }

                        if (response.status === 403) {
                            if (error.message === 'OTP has already been used') {
                                error.message = 'Magic link has already been used'
                            }
                            if (error.message === 'OTP has expired') {
                                error.message = 'Magic link has expired'
                            }
                        }

                        if (response.status !== 503) {
                            // blacklist of error codes to be hidden from toasts
                            if (
                                !suppressToast &&
                                ![
                                    'VerificationRequiredError',
                                    'RecipientNotFoundError',
                                    'Require2FACodeError',
                                    'KYCLimitReachedError',
                                ].includes(error.name)
                            ) {
                                if (error.errors?.length) {
                                    for (const errorText of error.errors) {
                                        this.toastr.error(errorText)
                                    }
                                } else {
                                    this.toastr.error(error.message)
                                }
                            }
                            return of(undefined).pipe(
                                flatMap(() => {
                                    if (
                                        shouldLogOut &&
                                        err.status === 401 &&
                                        this.session.isAuthenticated() &&
                                        // These errors should not lead to logging out the user
                                        ![
                                            'Wrong2FACodeError',
                                            'Require2FACodeError',
                                            'WrongCredentialsError',
                                            'WrongPasswordError',
                                        ].includes(error.name)
                                    ) {
                                        return this.logoutService.logout()
                                    }
                                    if (error.name === 'VerificationRequiredError') {
                                        this.modal.dismissAll()
                                        openNoAccessModal(this.modal, error.name)
                                    }
                                    if (error.name === 'KYCLimitReachedError') {
                                        this.modal.dismissAll()
                                        openKycLimitReachedModal(this.modal)
                                    }
                                    return of(undefined)
                                }),
                                flatMap(() =>
                                    throwError(
                                        Object.assign(error, {
                                            severity: 'danger',
                                            name: error.name || response.name || 'Server error',
                                            status: response.status,
                                            caughtByInterceptor: true,
                                        })
                                    )
                                )
                            )
                        } else {
                            // backend is in maintenance mode, reload to see if the frontend is affected as well
                            setTimeout(() => window.location.reload(), 1000)
                        }
                    }
                    return throwError(err)
                })
            )
    }
}
