import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { CopyIconComponent } from '../copy-icon/copy-icon.component'
import { CopierService } from '../services/copier.service'

@Component({
    selector: 'copy-btn',
    templateUrl: 'copy-btn.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyButtonComponent extends CopyIconComponent {
    @Input()
    public disabled: boolean

    constructor(
        copier: CopierService,
        changeDetector: ChangeDetectorRef,
        public translate: TranslateService
    ) {
        super(copier, changeDetector)
    }
}
