import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

@Component({
    selector: 'kyc-limit-reached-modal',
    templateUrl: 'kyc-limit-reached-modal.component.html',
})
export class KycLimitReachedModalComponent {
    constructor(
        public activeModal: NgbActiveModal,
        public router: Router
    ) {}

    public async goToVerification(): Promise<void> {
        this.activeModal.dismiss()
        await this.router.navigate(['register', 'identity-verification'])
    }
}

export function openKycLimitReachedModal(ngbModal: NgbModal): NgbModalRef {
    const modal = ngbModal.open(KycLimitReachedModalComponent, {
        windowClass: 'modal-primary',
    })
    return modal
}
