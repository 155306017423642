<form
    class="form-validation"
    #walletForm="ngForm"
    (ngSubmit)="submit()"
    [ngClass]="{ 'ng-submitted': walletForm.submitted }"
    autocomplete="false"
    novalidate
>
    <div class="modal-header">
        <h5 class="modal-title">{{ translateAction(action) }} {{ 'common.balance' | translate | lowercase }}</h5>
        <button type="button" class="btn btn-round close close-modal" (click)="activeModal.dismiss()">&times;</button>
    </div>
    <ul class="nav nav-tabs-alt nav-fill">
        <li class="nav-item">
            <a href class="nav-link" [ngClass]="{ active: action === 'freeze' }" (click)="changeAction('freeze'); $event.preventDefault()">
                {{ 'common.freeze' | translate }}
            </a>
        </li>
        <li class="nav-item">
            <a href class="nav-link" [ngClass]="{ active: action === 'unfreeze' }" (click)="changeAction('unfreeze'); $event.preventDefault()">
                {{ 'common.unfreeze' | translate }}
            </a>
        </li>
    </ul>
    <div class="modal-body">
        <div class="row">
            <ng-container *ngIf="action === 'freeze'; else unfreezeTemplate">
                <div class="form-group col">
                    <label for="amount">{{ 'common.amount' | translate }}</label>
                    <input
                        type="number"
                        class="form-control"
                        id="amount"
                        name="amount"
                        [placeholder]="'common.enter-amount' | translate"
                        [(ngModel)]="amount"
                        #amountInput="ngModel"
                        required
                    />
                    <div *ngIf="amountInput.invalid && (walletForm.submitted || amountInput.dirty)" class="error-messages">
                        <div class="form-control-feedback" *ngIf="amountInput?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                    </div>
                </div>
            </ng-container>
            <ng-template #unfreezeTemplate>
                <div class="col">
                    {{
                        (!hasFrozenBalance(wallet) && action === 'unfreeze'
                            ? 'common.user-does-not-has-frozen-balance'
                            : 'common.are-you-sure-want-to-unfreeze'
                        ) | translate
                    }}
                </div>
            </ng-template>
        </div>
    </div>
    <div class="modal-footer">
        <button
            [disabled]="!hasFrozenBalance(wallet) && action === 'unfreeze'"
            type="submit"
            class="btn btn-primary btn-w-sm"
            [ngClass]="{ loading: isSaving }"
            [disabled]="isSaving"
        >
            {{ translateAction(action) }}
        </button>
    </div>
</form>
