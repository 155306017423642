<ng-container [ngSwitch]="notification.type">
    <!-- Verification -->
    <ng-container *ngSwitchCase="'verification-approved'"> {{ 'common.your-account-verified' | translate }} </ng-container>

    <!-- Transactions -->
    <ng-container *ngSwitchCase="'transaction-processed'">
        <ng-container [ngSwitch]="transaction?.type">
            <ng-container *ngSwitchCase="'deposit'">
                {{ 'common.your' | translate }} <span class="text-info text-uppercase">{{ transaction?.method }}</span>
                {{ 'common.deposit' | translate | lowercase }} <span class="text-info">{{ transaction?.id | truncate: false : 7 : '' }}</span>
                {{ 'common.of' | translate }}
                <span class="text-green"
                    >{{ transaction?.amount | amount: transaction?.currency?.decimalPlaces }} {{ transaction?.currency?.code }}</span
                >
                {{ 'common.has-been-completed' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="'transfer'">
                {{ 'common.you-received' | translate }}
                <span class="text-green"
                    >{{ transaction?.settledAmount | amount: transaction?.currency?.decimalPlaces }} {{ transaction?.currency?.code }}</span
                >
                {{ 'common.from' | translate | lowercase }}
                <ng-container *ngTemplateOutlet="userOrAppTemplate; context: transaction?.baseWallet"></ng-container> {{ 'common.using' | translate }}
                <span class="text-info">{{ transaction?.id | truncate: false : 7 : '' }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'withdrawal'">
                {{ 'common.your' | translate }} <span class="text-info text-uppercase">{{ transaction?.method }}</span>
                {{ 'common.withdrawal' | translate | lowercase }} <span class="text-info">{{ transaction?.id | truncate: false : 7 : '' }}</span>
                {{ 'common.of' | translate }}
                <span class="text-green"
                    >{{ transaction?.amount | amount: transaction?.currency?.decimalPlaces }} {{ transaction?.currency?.code }}</span
                >
                {{ 'common.has-been-completed' | translate }}
            </ng-container>
        </ng-container>
    </ng-container>

    <!-- Signatory -->
    <ng-container *ngSwitchCase="'signature-requested'">
        {{ 'signatory.you-have-been-requested' | translate }}
        <span class="text-info">{{ transaction?.id | truncate: false : 7 : '' }}</span
        >&nbsp;
        <button
            class="btn btn-success btn-sm"
            [routerLink]="['/transactions', transaction?.id]"
            [queryParams]="{ action: 'approve', txId: transaction?.id }"
            (click)="$event.preventDefault(); $event.stopPropagation()"
        >
            {{ 'common.approve' | translate }}
        </button>
    </ng-container>

    <!-- Conversions -->
    <ng-container *ngSwitchCase="'conversion-offered'">
        {{ 'common.you-received-an-offer' | translate }}
        <span class="text-danger"
            >{{ conversion?.baseTransaction?.amount | amount: conversion?.baseTransaction?.currency?.decimalPlaces }}
            {{ conversion?.baseTransaction?.currency?.code }}</span
        >
        to
        <span class="text-green"
            >{{ conversion?.counterTransaction?.amount | amount: conversion?.counterTransaction?.currency?.decimalPlaces }}
            {{ conversion?.counterTransaction?.currency?.code }}
        </span>
        <ng-container *ngIf="conversion?.status === 'offered'"
            ><button
                class="btn btn-success btn-sm e2e-accept-conversion-offer-btn"
                [routerLink]="['/services/conversions', conversion?.id]"
                [queryParams]="{ action: 'accept' }"
                (click)="$event.preventDefault(); $event.stopPropagation()"
            >
                {{ 'common.accept' | translate }}
            </button>
            /
            <button
                class="btn btn-danger btn-sm e2e-reject-conversion-offer-btn"
                [routerLink]="['/services/conversions', conversion?.id]"
                [queryParams]="{ action: 'decline' }"
                (click)="$event.preventDefault(); $event.stopPropagation()"
            >
                {{ 'common.decline' | translate }}
            </button></ng-container
        >
        <span class="text-danger" *ngIf="conversion && ['declined', 'cancelled'].includes(conversion.status)"
            >({{ (conversion?.status === 'declined' ? 'common.declined' : 'common.cancelled') | translate }})</span
        >
    </ng-container>

    <!-- Invoice -->
    <ng-container *ngSwitchCase="'invoice-received'">
        {{ 'common.you-receive-an-invoice' | translate }}: <span class="text-info">{{ invoice?.subject }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="'invoice-cancelled'">
        {{ 'common.invoice-cancelled' | translate }}: <span class="text-danger">{{ invoice?.subject }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="'invoice-settled'">
        {{ 'common.invoice-settled' | translate }}: <span class="text-success">{{ invoice?.subject }}</span>
    </ng-container>

    <!-- File -->
    <ng-container *ngSwitchCase="'poi-document-expiry'">
        <span class="text-warning">{{ 'common.your-poi-expire' | translate }}</span>
    </ng-container>

    <!-- Contact -->
    <ng-container *ngSwitchCase="'added-as-contact'">
        <ng-container *ngTemplateOutlet="userOrAppTemplate; context: { user: contact?.baseUser }"></ng-container>
        {{ 'common.added-as-contact' | translate }}
    </ng-container>

    <!-- Card Order -->
    <ng-container *ngSwitchCase="'card-order-approved'">
        {{ 'common.your-card-order' | translate }} <span class="text-info">{{ cardOrder?.id | truncate: false : 7 : '' }}</span>
        {{ 'common.has-been-approved' | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="'card-order-shipped'">
        {{ 'common.your-card-order' | translate }} <span class="text-info">{{ cardOrder?.id | truncate: false : 7 : '' }}</span>
        {{ 'common.has-been-shipped' | translate }}
    </ng-container>
</ng-container>

<!-- Helper -->
<ng-template let-user="user" #userTemplate>
    <copy-wrap [value]="user?.accountNumber">
        <span [ngbTooltip]="user?.accountNumber | accountNumber">{{ user?.name }}</span>
    </copy-wrap>
</ng-template>
<ng-template let-user="user" #userOrAppTemplate>
    <ng-container *ngIf="user; else app">
        <copy-wrap *ngIf="allowUserCopy" [value]="user.accountNumber">
            <ng-container *ngTemplateOutlet="account"></ng-container>
        </copy-wrap>
        <ng-container *ngIf="!allowUserCopy">
            <ng-container *ngTemplateOutlet="account"></ng-container>
        </ng-container>
        <ng-template #account>
            <span [ngbTooltip]="user?.accountNumber | accountNumber">{{ user.name }}</span>
        </ng-template>
    </ng-container>
    <ng-template #app>
        {{ displayName }}
    </ng-template>
</ng-template>
