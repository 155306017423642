<label class="font-md">{{ label }}</label>
<div>
    <img *ngIf="preview && !file" [src]="preview" class="img-sm d-block my-2" />
    <button type="button" class="btn btn-primary" ngfSelect accept="image/*" [maxSize]="5242880" (fileChange)="fileChange.emit($event)" [file]="file">
        {{ 'common.choose-a-file' | translate }}
    </button>
    <ng-container *ngIf="file">
        <span class="ms-2">{{ file?.name }}</span>
    </ng-container>
</div>
