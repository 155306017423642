import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of, NEVER } from 'rxjs'
import { mergeMap, switchMap } from 'rxjs/operators'
import { LogoutService } from './services/logout.service'
import { SessionService } from './services/session.service'

@Injectable()
export class TokenRequestInterceptor implements HttpInterceptor {
    public readonly unauthorizedRoutes = ['/token']
    constructor(
        private session: SessionService,
        private logout: LogoutService
    ) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return of(undefined).pipe(
            switchMap(() => {
                if (this.session.hasValidRefreshToken() && this.session.getExpiryTimeInSeconds() < 15) {
                    return this.logout.logout().pipe(mergeMap(() => NEVER))
                }
                let headers = req.headers || new HttpHeaders()
                if (req.url !== '/token' && this.session.hasValidToken() && !headers.has('Authorization')) {
                    headers = headers.set('Authorization', 'Bearer ' + this.session.token)
                }
                return next.handle(req.clone({ headers, url: '/.api' + req.url }))
            })
        )
    }
}
