<div class="table-responsive">
    <table class="table table-bordered" *ngIf="chainalysisApiCall; else notFound">
        <tbody>
            <tr>
                <td>{{ 'chainalysis.asset' | translate }}</td>
                <td>{{ chainalysisApiCall.data?.request.body.asset ?? '' }}</td>
            </tr>
            <tr>
                <td>{{ 'common.amount' | translate }}</td>
                <td>{{ chainalysisApiCall.data?.request.body.assetAmount ?? '' }}</td>
            </tr>
            <tr>
                <td>{{ 'chainalysis.network' | translate }}</td>
                <td>{{ chainalysisApiCall.data?.request.body.network ?? '' }}</td>
            </tr>
            <tr>
                <td>{{ 'chainalysis.direction' | translate }}</td>
                <td>{{ chainalysisApiCall.data?.request.body.direction ?? '' | uppercase }}</td>
            </tr>
            <tr>
                <td>{{ 'common.transfer' | translate }} {{ 'common.reference' | translate | lowercase }}</td>
                <td class="text-break">{{ chainalysisApiCall.data?.request.body.transferReference ?? '' }}</td>
            </tr>
            <tr>
                <td
                    [ngStyle]="{
                        'vertical-align': 'top',
                    }"
                >
                    {{ 'common.alerts' | translate }}
                </td>
                <td class="p-0">
                    <tbody>
                        <ng-container *ngIf="chainalysisAlerts && chainalysisAlerts.length > 0; else notFound">
                            <ng-container *ngFor="let chainalysisAlert of chainalysisAlerts">
                                <tr>
                                    <td>External ID</td>
                                    <td>
                                        <uuid [value]="chainalysisAlert.externalId"></uuid>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ 'chainalysis.alert-level' | translate }}</td>
                                    <td>
                                        <span
                                            class="text-capitalize badge badge-lg"
                                            [ngClass]="{
                                                'bg-success': chainalysisAlert.alertLevel === 'LOW',
                                                'bg-info': chainalysisAlert.alertLevel === 'MEDIUM',
                                                'bg-warning': chainalysisAlert.alertLevel === 'HIGH',
                                                'bg-danger': chainalysisAlert.alertLevel === 'SEVERE',
                                            }"
                                            >{{ chainalysisAlert.alertLevel }}</span
                                        >
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ 'chainalysis.alert-amount' | translate }} (USD)</td>
                                    <td>
                                        <div>{{ chainalysisAlert.alertAmount }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ 'chainalysis.category' | translate }}</td>
                                    <td>
                                        <div>{{ chainalysisAlert.category | startCase }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{ 'chainalysis.exposure-type' | translate }}
                                    </td>
                                    <td>
                                        <div>{{ chainalysisAlert.exposureType }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{ 'chainalysis.service' | translate }}
                                    </td>

                                    <td>
                                        <div>{{ chainalysisAlert.service }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <a
                                            class="btn btn-primary btn-icon btn-sm"
                                            [href]="'https://kyt.chainalysis.com/alerts/' + chainalysisAlert.externalId"
                                            target="_blank"
                                        >
                                            <fa-icon class="btn-icon-symbol" [fixedWidth]="true" [icon]="faEye"></fa-icon>
                                            <span>{{ 'common.view-on' | translate }} Chainalysis</span>
                                        </a>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #notFound>
    <table class="table">
        <tr>
            <td colspan="7" class="text-center border-0">{{ 'common.no-entries-found' | translate }}</td>
        </tr>
    </table>
</ng-template>
