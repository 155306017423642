<div class="modal-header">
    <h5 class="modal-title">{{ 'two-factor-modal.enable-2fa' | translate }}</h5>
    <button type="button" class="btn btn-round close close-modal" (click)="activeModal.dismiss()">&times;</button>
</div>
<div class="modal-body">
    <form name="twoFactor" #twoFactorForm="ngForm" class="form-validation" (ngSubmit)="enableTwoFactor()" autocomplete="false" novalidate>
        <div>
            <img [src]="twoFactor?.qrCode" class="mx-auto d-block img-thumbnail p-0 mb-2" />
            <p class="text-center">
                {{ 'two-factor-modal.or-enter-this-code' | translate }} <b class="text-info">{{ twoFactor?.secret }}</b>
            </p>
            <div class="alert alert-info">
                <p>{{ 'two-factor-modal.scan-this-code' | translate }}</p>

                <div class="font-sm">
                    <p class="mb-0">{{ 'two-factor-modal.learn-more' | translate }}</p>
                    <a class="d-block" href="https://help.aerapass.io/en/articles/1721919-what-is-2-factor-authentication" target="_blank">{{
                        'two-factor-modal.what-is-2fa' | translate
                    }}</a>
                    <a class="d-block" href="https://help.aerapass.io/en/articles/4604901-how-to-enable-2-factor-authentication" target="_blank">{{
                        'two-factor-modal.how-to-use' | translate
                    }}</a>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="verificationCode">{{ 'two-factor-modal.verification-code' | translate }}</label>
            <input type="number" id="verificationCode" name="verificationCode" class="form-control" [(ngModel)]="verificationCode" />
        </div>
        <div class="d-flex justify-content-end">
            <button type="submit" class="btn btn-primary btn-w-sm" [ngClass]="{ loading: isLoading }" [disabled]="isLoading">
                <span>{{ 'two-factor-modal.enable-2fa' | translate }}</span>
            </button>
        </div>
    </form>
</div>
