import { countries, Country } from '@aerapass/country-data'
import BigNumber from 'bignumber.js'
import { omitBy, startCase } from 'lodash'
import validateEmail from 'validator/lib/isEmail'
import { AccountBill, IAddress, Permission } from './api-interfaces'
import { countryMap } from './countries'

export type BootstrapComponentType =
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'default'
    | 'medium'
    | 'dark'

export function convertToISODate(date: string): string {
    return `${date.slice(4)}-${date.slice(2, 4)}-${date.slice(0, 2)}`
}

export function convertPercentage(percentage: string): string {
    return new BigNumber(percentage).div(100).toPrecision(9, 1)
}

/**
 * Returns the calling code of a country
 * @param country Alpha2 of a country
 */
export function toCountryCallingCode(country: string): string {
    return countryMap[country]?.countryCallingCodes[0] ?? ''
}

export function nanToNull<T>(value: T): T | null {
    return isNaN(value as any) ? null : value
}

export function emptyToNull<T>(value: T): T | null {
    return !value ? null : value
}

export function toStartCase(text: string | null): string {
    return text ? startCase(text.replace(/-/g, ' ')) : ''
}

export function isZero(value: number | string): boolean {
    return new BigNumber(value).isZero()
}

export function formatAccountNumber(value: string): string {
    return `${value.substring(0, 2)} ${value.substring(2, 6)} ${value.substring(6, 10)} ${value.substring(10)}`
}

/**
 * Removes properties with null or undefined values
 */
export function deepOmitEmptyProperties(object: any): any {
    if (typeof object !== 'object') {
        throw new Error('Argument not an object')
    }
    object = omitBy(object, prop => prop === null || prop === undefined)
    for (const key in object) {
        if (Array.isArray(object[key])) {
            object[key] = object[key].map((item: any) => {
                if (typeof item === 'object') {
                    return deepOmitEmptyProperties(item)
                }
                return item
            })
        } else if (typeof object[key] === 'object') {
            object[key] = deepOmitEmptyProperties(object[key])
        }
    }
    return object
}

export function searchCountryCode(term: string, country: Country): boolean {
    return (
        country.alpha2.toLocaleLowerCase().includes(term.toLocaleLowerCase()) ||
        country.countryCallingCodes[0].toLocaleLowerCase().includes(term.toLocaleLowerCase())
    )
}

export function transformAddress(
    address: {
        addressLine1: string | null
        addressLine2?: string | null
        zipCode: string | null
        city: string | null
        country: string | null
    },
    compact: boolean
): { name: string | null } | { formatted_address: string } | null {
    return address.addressLine1
        ? compact
            ? {
                  formatted_address: `${address.addressLine1}${
                      address.addressLine2 ? ', ' + address.addressLine2 : ''
                  }, ${address.zipCode ? address.zipCode + ', ' : ''}${address.city ? address.city + ', ' : ''}${
                      address.country ? countryMap[address.country].name : ''
                  }`,
              }
            : { name: address.addressLine1 }
        : null
}

export function dataUrlToBlob(dataUrl: string, type: string): Blob {
    const byteString = atob(dataUrl.split(',')[1])
    const ia = new Uint8Array(byteString.length)
    let i = byteString.length
    while (i--) {
        ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ia], { type })
}

export const permissionMap: { [key in Permission]: string } = {
    'payment-swift': 'Swift',
    'payment-local': 'Local',
    'payment-crypto': 'Crypto',
    'payment-card': 'Payment card',
    cards: 'Cards',
    conversions: 'Conversions',
    invoices: 'Invoices',
    marketplaces: 'Marketplaces',
    'withdrawal-own': 'Withdrawal Own',
    'withdrawal-third-party': 'Withdrawal Third Party',
    'withdrawal-fourth-party': 'Withdrawal Fourth Party',
}

export function determineAccountBillStatus(accountBill: AccountBill): string {
    if (new BigNumber(accountBill.amount).eq(0)) {
        return 'free'
    }
    if (!accountBill.transaction) {
        return 'unpaid'
    }
    return 'paid'
}

export function readFileAsDataURL(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
            resolve(reader.result)
        }
        reader.onerror = error => {
            reject(error)
        }
        reader.readAsDataURL(file)
    })
}

export function isEmail(input: string): boolean {
    return validateEmail(input)
}

export function isAccountNumber(input: string, option?: { allowSpaces: boolean }): boolean {
    const format = RegExp('^[A-Z]{2}[0-9]{10}$', 'g')
    const value = !!option && option.allowSpaces ? input.replace(/\s/g, '') : input
    return (
        format.test(value) &&
        parseInt(`${value.charCodeAt(0)}${value.charCodeAt(1)}${value.substring(2)}`, 10) % 97 === 1
    )
}

export function findCountry(alpha2: string): Country | undefined {
    return countries.all.find(country => country.alpha2 === alpha2)
}

export function formatAddressToUSFormat(address: IAddress): string {
    if (!address) {
        return ''
    }

    const { addressLine1, addressLine2, city, state, zipCode, country } = address

    const countryName = country ? countryMap[country].name : ''

    return `${addressLine1}\n${addressLine2 ? `${addressLine2}\n` : ''}${city ? city + ' ' : ''}${
        state ? state + ' ' : ''
    }${zipCode ? zipCode : ''}${countryName ? '\n' + countryName : ''}`
}
