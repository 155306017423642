<div class="modal-header">
    <h5 class="modal-title">Oops! Something went wrong</h5>
    <button type="button" class="btn btn-round close close-modal" (click)="activeModal.dismiss()">&times;</button>
</div>
<div class="modal-body">
    <div class="d-flex justify-content-center">
        <img src="/img/mail/icon-warning.png" class="img-md my-3" />
    </div>
    <h5 class="text-center">We cannot complete this request right now</h5>
    <p class="text-center">Please try again or contact support if the issue persists.</p>
    <div class="d-flex justify-content-center">
        <button class="btn btn-warning-air btn-w-lg" (click)="intercom.startConversation('Hi, I am having trouble ordering a card. Kindly assist.')">
            <fa-icon [icon]="faHeadset"></fa-icon> Contact support
        </button>
    </div>
</div>
