import { Pipe, PipeTransform } from '@angular/core'
import { SafeUrl } from '@angular/platform-browser'

@Pipe({ name: 'uppercaserizer' })
export class UppercaserizerPipe implements PipeTransform {
    public transform(value: any): SafeUrl {
        if (typeof value !== 'string') {
            return value
        }

        return value.replace(/\b(?:id|Id)\b/g, 'ID')
    }
}
