<a *ngIf="file.status === 'safe' && file.signedUrl && canView; else processing" (click)="$event.preventDefault(); open(file)" class="clickable">
    <div>{{ file.name }}</div>
</a>
<ng-template #processing>
    <div *ngIf="file.status === 'processing'; else infected" class="text-warning">
        {{ file.name }} <small>({{ 'common.scanning' | translate }})</small>
    </div>
</ng-template>
<ng-template #infected>
    <div *ngIf="file.status === 'infected'; else plain" class="text-danger">
        {{ file.name }} <small>({{ 'common.virus-found' | translate }})</small>
    </div>
</ng-template>
<ng-template #plain>
    <div class="text-primary">{{ file.name }}</div>
</ng-template>
<small *ngIf="file.userFile; else alternativeInfo" class="text-muted cursor"
    >{{ file.size / 1024 / 1024 | number: '.2' }} MB / {{ file.userFile.type ? toStartCase(file.userFile.type) : 'Undeclared' }}</small
>
<ng-template #alternativeInfo>
    <small class="text-muted">{{ file.size / 1024 / 1024 | number: '.2' }} MB</small>
</ng-template>
