import { Injectable } from '@angular/core'
import {
    faBan,
    faCheckCircle,
    faExclamationCircle,
    faInfoCircle,
    IconDefinition,
} from '@fortawesome/pro-solid-svg-icons'
import { Subject } from 'rxjs'

export type ToastType = 'info' | 'warning' | 'success' | 'error'

export interface Toast {
    id: number
    type: ToastType
    message: string
    title?: string
    icon: IconDefinition
    timeout?: NodeJS.Timeout
}

@Injectable({
    providedIn: 'root',
})
export class ToastrService {
    private toastSource = new Subject<Toast>()
    private lastToastId = 0
    private iconClasses: { [key in ToastType]: IconDefinition } = {
        error: faBan,
        warning: faExclamationCircle,
        success: faCheckCircle,
        info: faInfoCircle,
    }
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public toastPushed = this.toastSource.asObservable()

    public log(type: ToastType, message: string, title?: string): void {
        title = title || 'Server Message'
        this.toastSource.next({
            id: this.lastToastId++,
            icon: this.iconClasses[type],
            type,
            message,
            title,
        })
    }
    public info(message: string, title?: string): void {
        this.log('info', message, title)
    }
    public warning(message: string, title?: string): void {
        this.log('warning', message, title)
    }
    public success(message: string, title?: string): void {
        this.log('success', message, title)
    }
    public error(message: string, title?: string): void {
        this.log('error', message, title)
    }
}
