<ng-select
    [class.ng-select-lg]="isLarge"
    [class]="additionalClasses"
    name="currency-select"
    [placeholder]="placeholder"
    [(ngModel)]="currency"
    [items]="balances$ | async"
    [clearable]="false"
    [searchable]="false"
    [disabled]="disabled"
    [required]="required"
    (change)="currencyChange.emit($event)"
    (open)="currencySelectOpen.emit()"
    [compareWith]="compareCurrencies"
    #currencyInput="ngModel"
>
    <ng-template ng-label-tmp let-item="item">
        <b class="me-1">
            <ng-container [ngSwitch]="item.code">
                <ng-container *ngSwitchCase="'AU'"> Gold </ng-container>
                <ng-container *ngSwitchCase="'AG'"> Silver </ng-container>
                <ng-container *ngSwitchDefault>{{ useFullName ? item.name : item.code }}</ng-container>
            </ng-container>
        </b>
        <span *ngIf="showBalance" class="small text-muted">
            {{ item.balance | amount: item.decimalPlaces }} {{ item.code }}
            <ng-container *ngIf="['crypto', 'metal'].includes(item.type) && !item.code.includes('USD')">
                (≈ {{ item.value | amount: 2 }} {{ (preferredCurrency$ | async)?.code }})
            </ng-container>
        </span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <ng-container [ngSwitch]="item.code">
            <ng-container *ngSwitchCase="'AU'"> Gold </ng-container>
            <ng-container *ngSwitchCase="'AG'"> Silver </ng-container>
            <ng-container *ngSwitchDefault>{{ useFullName ? item.name : item.code }}</ng-container>
        </ng-container>
        <span *ngIf="showBalance" class="float-end small text-muted">
            {{ item.balance | amount: item.decimalPlaces }} {{ item.code }}
            <ng-container *ngIf="['crypto', 'metal'].includes(item.type) && !item.code.includes('USD')">
                (≈ {{ item.value | amount: 2 }} {{ (preferredCurrency$ | async)?.code }})
            </ng-container>
        </span>
    </ng-template>
</ng-select>
